<template>
    <div class="mx-10">
        <products-overview>
        </products-overview>
    </div>
</template>

<script>
import ProductsOverview from '../components/ProductsOverview/Index.vue'
    export default {
        components:{
            ProductsOverview
        }
    }
</script>

