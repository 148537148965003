var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"mt-3"},[_vm._v(" Manage Products ")]),_c('v-divider',{staticClass:"mx-16"}),_c('div',{staticClass:"d-flex justify-end mx-16 mt-5"},[_c('add-product',{on:{"saved":function($event){return _vm.GetProducts()}}})],1),_c('div',{staticClass:"mx-16 d-flex justify-start mt-3"},[_c('div',{staticClass:"w-20-md"},[_c('v-text-field',{attrs:{"label":"Filter","outlined":"","clearable":"","append-icon":"mdi-magnify","hide-details":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('v-btn',{staticClass:"my-auto ml-6",attrs:{"color":"primary"},on:{"click":_vm.GetProducts}},[_vm._v(" Refresh "),_c('v-icon',[_vm._v("refresh")])],1)],1),(_vm.error)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Error please refresh and try again. ")]):_vm._e(),_c('div',{staticClass:"mx-16"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.products,"search":_vm.filter,"loading":_vm.loadingProducts,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.PrimaryImage",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":item.PrimaryImage,"max-width":"200","contain":""}})]}},{key:"item.Product.Price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.Product.Price))+" ")]}},{key:"item.Product",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"info"},on:{"click":function($event){return _vm.EditProduct(item)}}},[_vm._v(" Edit "),_c('v-icon',[_vm._v("edit")])],1)]}},{key:"item.Delete",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.dialogDelete = true; _vm.product = item; _vm.deleteIndex = _vm.products.indexOf(item)}}},[_vm._v(" Delete "),_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)})],1),_c('manage-product',{attrs:{"product":_vm.product.Product == null ? _vm.product : _vm.product.Product,"openDialog":_vm.openDialog,"productId":_vm.product.Id},on:{"close-dialog":function($event){_vm.openDialog = false},"saved":function($event){return _vm.UpdateProduct()}}}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":_vm.processing},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":_vm.processing},on:{"click":function($event){return _vm.DeleteProduct()}}},[_vm._v("OK "),(_vm.processing)?_c('v-progress-circular',{attrs:{"indeterminate":""}}):_vm._e()],1),_c('v-spacer')],1)],1)],1),_c('v-snackbar',{attrs:{"color":"error","top":"","right":""},model:{value:(_vm.errorToast),callback:function ($$v) {_vm.errorToast=$$v},expression:"errorToast"}},[_c('v-icon',[_vm._v("error_outline")]),_vm._v(" Please refresh and try again ")],1),_c('v-snackbar',{attrs:{"color":"success","top":"","right":""},model:{value:(_vm.successToast),callback:function ($$v) {_vm.successToast=$$v},expression:"successToast"}},[_c('v-icon',[_vm._v("check_circle")]),_vm._v(" Success! ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }