<template>
    <v-footer
        padless
        color="#540100"
    >
        <v-row
        justify="center"
        no-gutters
        >
            <router-link :to="link.Link" v-for="link in links" :key="link.Name">
                <v-btn
                    color="white"
                    text
                    rounded
                    class="my-2"
                >
                    {{ link.Name }}
                </v-btn>
            </router-link>
        <v-col
            class="dark py-4 text-center white--text"
            cols="12"
        >
            {{ new Date().getFullYear() }} — <strong>Chain Saf</strong>
        </v-col>
        </v-row>
  </v-footer>
</template>

<script>
    export default {
        props:{
            loggedIn: Boolean
        },
        data: () => ({
            links: [
                { 
                    Link: '/',
                    Name: 'Home'
                },
                { 
                    Link: '/Videos',
                    Name: 'Videos'
                },
                { 
                    Link: '/Pictures',
                    Name: 'Pictures'
                },
                { 
                    Link: '/ContactUs',
                    Name: 'Contact Chainsaf'
                },
                { 
                    Link: '/AboutUs',
                    Name: 'The Story Of Chainsaf'
                },
                { 
                    Link: '/HowTo/Use',
                    Name: 'How To Use'
                },
                { 
                    Link: '/HowTo/Install',
                    Name: 'How To Install'
                },
            ],
        }),                                                                                                                                                                                                                                                                                                                                                                                                     
        watch:{
            loggedIn(){
                if(!this.loggedIn){
                    this.links.push({
                        Link: '/Login',
                        Name: 'Admin Login'
                    })
                }
                else{
                    this.links  = this.links.filter(element => {return element.Link !== '/Login'})
                }
            }
        },
        mounted(){
            if(!this.loggedIn){
                this.links.push({
                    Link: '/Login',
                    Name: 'Admin Login'
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>