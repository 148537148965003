<template>
    <div>
         <v-dialog
            v-model="openDialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            v-if="video != null"
            >
            <v-card>
                <v-toolbar
                dark
                color="primary"
                >
                    <v-btn
                        icon
                        dark
                        @click="openDialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Manage Video</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                        dark
                        text
                        @click="Save()"
                        :disabled="video.Name == '' || video.Description == '' || video.VideoUrl == '' || processing"
                        >
                        Save <v-progress-circular v-if="processing" indeterminate></v-progress-circular>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-alert v-if="error" type="error">
                    Error please refresh and try again.
                </v-alert>
                <v-container>
                    <v-row>
                       <v-col md="8" cols="12">
                           <v-text-field
                            v-model="video.Name"
                            label="Video Name"
                            :disabled="processing"
                            outlined
                            clearable
                            required
                            ></v-text-field>
                       </v-col> 
                       <v-col md="4" cols="12">
                           <v-file-input
                                multiple
                                label="File input"
                                v-model="tempFiles"
                                @change="PreviewMedia"
                                :disabled="processing"
                                accept="video/*"
                            ></v-file-input>

                            <p>
                                Please upload one video.
                            </p>
                       </v-col>
                       <v-col cols="12">
                           <video v-if="video.VideoUrl != '' && !converting" width="400" controls :key="video.VideoUrl">
                                <source :src="video.VideoUrl" type="video/mp4">
                                Your browser does not support HTML video.
                            </video>
                            <p v-else-if="converting">
                                Converting video please wait...This process might take the same amount of time as the video length.
                                If conversion time is taking too long please convert the video to a different type and then try again.
                                <v-progress-circular
                                indeterminate
                                color="primary"
                                ></v-progress-circular>
                            </p>
                       </v-col>
                       <v-col cols="12">
                           <p>
                               Please enter in a description of the video below and add any extra information you would like.
                           </p>
                           <quill-editor v-model="video.Description"></quill-editor>
                       </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
        <v-snackbar
        v-model="errorToast"
        color="error"
        top
        right
        >
        <v-icon>error_outline</v-icon> Please refresh and try again
        </v-snackbar>
        <v-snackbar
        v-model="successToast"
        color="success"
        top
        right
        >
        <v-icon>check_circle</v-icon> Success!
        </v-snackbar>
    </div>
</template>

<script>
import firebase from 'firebase'
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
const ffmpeg = createFFmpeg({ log: true  });
    export default {
        props:{
            video: Object,
            openDialog: Boolean,
            videoId: String
        },
        data() {
            return {
                tempFiles:[],
                uploadValue: 0,
                processing: false,
                error: false,
                errorToast: false,
                successToast: false,
                content: "",
                deleteNames: [],
                ready: false,
                converting: false
            }
        },
        methods: {
             async ConvertVideo(){
                 this.converting = true;
                 if(!this.ready){
                     setTimeout(() => {
                         this.test()
                     }, 10);
                     return;
                 }
                 
                ffmpeg.FS('writeFile', this.video.File.name, await fetchFile(this.video.VideoUrl));
                await ffmpeg.run('-i',  this.video.File.name, '-vcodec', 'libx264', '-crf', '27', '-preset', 'ultrafast', 'test.mp4');

                const filedata = ffmpeg.FS("readFile", "test.mp4");
                this.video.File = new Blob([filedata.buffer], { type: 'video/mp4' });
                this.video.VideoUrl = URL.createObjectURL(this.video.File);
                const cover = await this.GetVideoCover(this.video.File);
                this.video.VideoThumbnailBlob = cover; 
                this.converting = false;
            },
            async Load() {
                await ffmpeg.load();
                this.ready = true;
            },
            async PreviewMedia(){
                for(var x = 0; x < this.tempFiles.length; x++){
                    if(this.video.File == null){
                        this.deleteNames.push(this.video.MediaName);
                        this.deleteNames.push(this.video.VideoThumbnailName);
                    }
                    this.video.File = this.tempFiles[x];
                    this.video.VideoUrl = URL.createObjectURL(this.tempFiles[x]);
                    if(this.video.File.type == "video/quicktime"){
                        this.ConvertVideo();
                    }
                    else{
                        const cover = await this.GetVideoCover(this.tempFiles[x]);
                        this.video.VideoThumbnailBlob = cover; 
                    }
                    break;
                }
                this.tempFiles = [];
            },
            async Save(){
                this.processing = true;
                this.error = false;
                this.errorToast = false;
                this.successToast = false;
                if(this.video.File != null){
                    var result = await this.UploadMedias(this.video.VideoThumbnailBlob);
                    this.$set(this.video, 'ThumbnailUrl', result.Url);
                    this.$set(this.video, 'VideoThumbnailName', result.MediaName)
                    this.$delete(this.video, 'VideoThumbnailBlob')
                    var res = await this.UploadMedias(this.video.File);
                    this.video.VideoUrl = res.Url;
                    this.video.MediaName = res.MediaName;
                    this.$delete(this.video, 'File')
                }

                firebase.firestore().collection("Videos")
                .doc(this.videoId)
                .update({  Video: this.video })
                .then(() => {
                    for(var x = 0; x < this.deleteNames.length; x++){
                        this.DeleteMediaFromStorage(this.deleteNames[x]);
                    }
                    this.successToast = true;
                    this.$emit('saved')
                })
                .catch(() => {
                    this.errorToast = true;
                    this.error = true;
                })
                .finally(() =>{
                    this.processing = false;
                })
            },
            UploadMedias(imgData){
                var guid = this.GetGuid();
                return new Promise((resolve) => {const storageRef=firebase.storage().ref(`Videos/${this.video.Name}_${guid}`).put(imgData);
                storageRef.on(`state_changed`,snapshot=>{
                this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
                    }, error=>{console.log(error.message)},
                    ()=>{this.uploadValue=100;
                    storageRef.snapshot.ref.getDownloadURL()
                        .then((url)=>{
                            //this.$set(this.product.Medias[index], 'MediaName', `${this.product.Name}_${guid}`)
                            resolve({Url: url, MediaName: `${this.video.Name}_${guid}`});
                        });
                    }      
                );
               });
            },
            DeleteMediaFromStorage(mediaName){
                const store = firebase.storage().ref();
                // Depending on which db you use and how you store, you get the filePath and delete it:
                store.child(`Videos/${mediaName}`).delete();
            }
        },
        mounted() {
            this.Load();
        },
    }
</script>

<style lang="scss" scoped>

</style>