<template>
    <div>
        <v-card
            @click="$router.push({name: 'Video', params: {videoId: video.Id}})"
            class="mx-auto my-12"
            max-width="374"
            hover
        >

            <v-img
                height="200"
                :src="video.ThumbnailUrl"
            ></v-img>

            <v-card-title>{{video.Name}}</v-card-title>

            <v-card-text>
                <p class="text-flow mt-5" v-html="video.Description">
                </p>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    export default {
        props:{
            video: Object
        }
    }
</script>

<style scoped>
.text-flow{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
        -webkit-box-orient: vertical;
    }
</style>