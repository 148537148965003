<template>
    <div class="mx-16">
        <h1 class="border-bottom text-left">
            Videos
        </h1>
        <video-display :videos="videos"></video-display>
        <div class="text-center my-5">
            <v-btn v-if="!atLimit" @click="GetVideos">
                Load More
            </v-btn>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase'
import VideoDisplay from './IndividualVideoDisplay.vue'
    export default {
        components:{
            VideoDisplay
        },
        data() {
            return {
                count: 0,
                videos: [],
                processing: false,
                lastOne: null,
                atLimit: false
            }
        },
        methods: {
            GetVideos(){
                this.processing = true;
                firebase.firestore().collection("Videos")
                .orderBy("Video", "desc")
                .startAfter(this.lastOne)
                .limit(5)
                .get().then((response) => {
                    //this.test = response.docs[0].data();
                    response.forEach((doc) => {
                        var tempVid = doc.data().Video;
                        this.$set(tempVid, 'Id', doc.id)
                        this.$set(this.videos, this.videos.length, tempVid);
                    });
                    if(response.docs.length != 5){
                        this.atLimit = true;
                    }
                    else{
                        this.lastOne = response.docs[response.docs.length - 1];
                    }
                })
            },
            GetCounters(){
                firebase.firestore().collection("Counters")
                .get("Counter")
                .then(response => {
                    response.forEach(doc => {
                        this.count = doc.data().VideoCounter;
                    });
                })
                .finally(() =>{
                    this.loadingProducts = false;
                })
            },
        },
        mounted() {
            //this.GetCounters();
            var next = firebase.firestore().collection("Videos")
            .orderBy("Video", "desc")
            .limit(5)
            
            next.get().then((response) => {
                this.videos = [];
                response.forEach((doc) => {
                    var tempVid = doc.data().Video;
                        this.$set(tempVid, 'Id', doc.id)
                        this.$set(this.videos, this.videos.length, tempVid);
                });
                this.lastOne = response.docs[response.docs.length - 1];
                if(response.docs.length != 5){
                    this.atLimit = true;
                }
            })
        },
    }
</script>

<style lang="scss" scoped>

</style>