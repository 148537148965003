import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import router from './router'
import store from './store'
import firebase from "firebase";
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueObserveVisibility from 'vue-observe-visibility'
import VueQuillEditor from 'vue-quill-editor'
import VueCoreVideoPlayer from 'vue-core-video-player'

import '../src/assets/chain.css'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme



var firebaseConfig = {
  apiKey: "AIzaSyAk7TbeNXyiZsrfC4NYO8sRFXgg3I1h_kc",
  authDomain: "chainboxx-9b8f3.firebaseapp.com",
  projectId: "chainboxx-9b8f3",
  storageBucket: "chainboxx-9b8f3.appspot.com",
  messagingSenderId: "49247507874",
  appId: "1:49247507874:web:fb856dee7ebe82affc0efb",
  measurementId: "G-TQ5E2MHPZZ"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

firebase.auth().onAuthStateChanged(user => {
  store.dispatch("fetchUser", user);
});

Vue.use(VueRouter)
Vue.use(VueObserveVisibility)
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
Vue.use(VueQuillEditor, {theme: 'snow'})
Vue.use(VueCoreVideoPlayer)

Vue.config.productionTip = false



Vue.mixin({
  methods: {
      GetGuid(){
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
      },
      formatMoney(number) {
        if(isNaN(Number(number))){
          return "";
        }
        else{
          return Number(number).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        } 
      },
      formatDate(dateString)
      {
          if(dateString == null){
              return null;
          }
          var date = new Date(dateString);
          var month = '' + (date.getMonth() + 1);
          var day = '' + date.getDate();
          var year = date.getFullYear();


          if (month.length < 2)
          {
              month = '0' + month;
          }
          if (day.length < 2)
          {
              day = '0' + day;
          }


          return [month, day, year].join('/');
      },
      IsImage(file){
        return !!file.type.match('image.*');
      },
      GetVideoCover(file) {
        console.log("getting video cover for file: ", file);
        var seekTo = 0;
        return new Promise((resolve, reject) => {
            // load the file to a video player
            const videoPlayer = document.createElement('video');
            videoPlayer.setAttribute('src', URL.createObjectURL(file));
            videoPlayer.load();
            videoPlayer.addEventListener('error', (ex) => {
                reject("error when loading video file", ex);
            });
            // load metadata of the video to get video duration and dimensions
            videoPlayer.addEventListener('loadedmetadata', () => {
                // seek to user defined timestamp (in seconds) if possible
                seekTo = videoPlayer.duration / 2;
                if (videoPlayer.duration < seekTo) {
                    reject("video is too short.");
                    return;
                }
                // delay seeking or else 'seeked' event won't fire on Safari
                setTimeout(() => {
                  videoPlayer.currentTime = seekTo;
                }, 200);
                // extract video thumbnail once seeking is complete
                videoPlayer.addEventListener('seeked', () => {
                    console.log('video is now paused at %ss.', seekTo);
                    // define a canvas to have the same dimension as the video
                    const canvas = document.createElement("canvas");
                    canvas.width = videoPlayer.videoWidth;
                    canvas.height = videoPlayer.videoHeight;
                    // draw the video frame to canvas
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
                    // return the canvas image as a blob
                    ctx.canvas.toBlob(
                        blob => {
                            resolve(blob);
                        },
                        "image/jpeg",
                        0.75 /* quality */
                    );
                });
            });
        });
    }
    }
})

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
