<template>
<v-col  md="4" cols="12">
    <v-card
        @click="$router.push({name: 'Product', params: {productId: product.Id}})"
        class="mx-auto my-12"
        max-width="374"
        hover
    >

        <v-img
            height="250"
            :src="product.PrimaryImage"
        ></v-img>

        <v-card-title>{{product.Product.Name}}<div class="ml-auto">{{formatMoney(product.Product.Price)}}</div></v-card-title>

        <v-card-text>
            <v-row
                align="center"
                class="mx-0"
            >
                <v-rating
                    :value="averageReviews"
                    color="amber"
                    dense
                    half-icon="star_half"
                    full-icon="star"
                    empty-icon="star_outline"
                    half-increments
                    readonly
                    size="14"
                ></v-rating>

                <div class="grey--text ms-4">
                {{averageReviews}} ({{product.Reviews.length}})
                </div>
            </v-row>

            <p class="text-flow mt-5" v-html="product.Product.Description">
            </p>
        </v-card-text>
    </v-card>
</v-col>
</template>

<script>
    export default {
        props:{
            product: Object
        },
        computed:{
            averageReviews(){
                if(this.product == null || this.product.Reviews.length == 0){
                    return 0;
                }
                else{
                    return (this.lodash.sumBy(this.product.Reviews, 'StarAmount') / this.product.Reviews.length).toFixed(1);
                }
            },
        }
    }

</script>

<style scoped>
    .text-flow{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
        -webkit-box-orient: vertical;
    }
</style>