<template>
    <div>
        <h1 class="mb-6" style="font-size:2.5rem">
            Why Chainsaf?  
            <br>
            Save Time<v-icon>noise_control_off</v-icon>Energy<v-icon>noise_control_off</v-icon>Money
        </h1>

        <v-container>
            <!-- <v-row>
                <v-col cols="12" md="4">
                    <v-img contain src="../../assets/Chainsaf (3).jpeg" :max-height="$vuetify.breakpoint.lgAndUp ? '500px' : '300px'">
                    </v-img>
                </v-col>
                <v-col cols="12" md="8"> -->
                    
                    <p class="info-font">
                        If you have secured equipment on a trailer, whether regularly or not, you know… No one looks forward to the task. 
                        Climbing on and off the trailer to drag heavy chains from the tow vehicle, looking for missing chains, binders or 
                        ratchet-straps,  climbing on and off the trailer to wrap around equipment then under the trailer to securement “points-of-pull”… 
                        Arrive at the job site and you get to do it all over again.
                    </p>
 
                    <v-row>
                        <v-col cols="12" md="3" class="my-2" order-md="last">
                            <v-img src="../../assets/IMG_1410.jpeg" max-height="400" contain class="my-2">
                            </v-img>

                            <v-img src="../../assets/IMG_1412.jpeg" max-height="400" contain class="my-2">
                            </v-img>
                        </v-col>
                        <v-col class="my-auto" cols="12" md="9">
                            <p class="info-font">
                                CHAINSAF solves it all!!!
                            </p>
                            <p class="info-font">
                                The innovative design of CHAINSAF solves storage and creates point-of-pull locations
                                on the conveniently on the deck of the trailer. Once equipment is loaded, never step off the trailer until
                                equipment is secure. Everything you need is at your fingertips from the trailer deck.
                            </p>
                            <p class="info-font">
                                Excess chain is stored conveniently within CHAINSAF during use. No more concerns of accidentally dragging excess chain.
                            </p>
                              <p class="info-font">
                                Chains AND binders (or ratchet straps) are secured and stored in CHAINSAF when not 
                                in use and always available right on the deck of the trailer. Chains and binders are with the trailer. 
                                No matter which truck you are towing with, everything you need is right there. Bolt the last link to the bottom
                                CHAINSAF to virtually eliminate loss or theft. 
                            </p>
                            <p class="info-font">
                                When the trailer is not secure, CHAINSAF's simple locking device, allows your high quality lock to be placed underneath, 
                                securing your chains, binders and ratchet-straps when not in use.
                            </p>
                            <p class="info-font">
                                CHAINSAF's flush mount design allows equipment to be driven right over it. Trailer deck remains clear.                    
                            </p>
                            <p class="info-font">
                                Private users will love the ease and simplicity of tying down equipment with CHASINSAF. 
                            </p>
                            <p class="info-font">
                                Employers will save on labor costs, reduce employee risk, reduce lost or stolen securement items, 
                                and loads will be consistently tied to dedicated points-of-pull.                     
                            </p>
                            <p class="info-font">
                                No other product offers so many solutions in one dedicated securement package as CHAINSAF.                    
                            </p>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="4" class="my-2" >
                            
                        </v-col>
                        <v-col class="my-auto" cols="12" md="8">
                          
                        </v-col>
                        
                    </v-row>
                    
                    

                    
                    
                <!-- </v-col>
            </v-row> -->
        </v-container>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style  scoped>
.info-font{
    font-size: 1.5em
}
</style>