<template>
    <div >
        <index :productId="productId"></index>
    </div>
</template>

<script>
import index from '../components/ContactUs/index.vue'
    export default {
        components:{
            index
        },
        data() {
            return {
                productId: ""
            }
        },
        mounted() {
            if(this.$route.query != null){
                this.productId = this.$route.query.productId;
            }
            else{
                this.productId = ''
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>