<template>
    <div>
        <h1 class="border-bottom text-left">
            Products
        </h1>

        <v-row v-if="loadingProducts">
            <v-col v-for="x in 6" :key="x" md="4" cols="12">
                <v-skeleton-loader max-width="374"
                    v-bind="attrs"
                    type="image, article"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
        <transition-group v-else name="product" tag="div" class="row">
            <single-product v-for="product in products" :key="product.Id" :product="product">
            </single-product>
        </transition-group>
    </div>
</template>

<script>
import SingleProduct from './SingleProduct.vue'
import firebase from 'firebase'

    export default {
        components:{
            SingleProduct
        },
        data() {
            return {
                products: [],
                attrs: {
                    class: 'mx-auto my-12',
                    boilerplate: true,
                    elevation: 2,
                },
                loadingProducts: false
            }
        },
        methods: {
            GetProducts(){
                this.loadingProducts = true;
                this.products = []
                firebase.firestore().collection("Products")
                .get()
                .then(response => {
                    response.forEach(doc => {
                        console.log(doc.data());
                        console.log(doc.id);
                        var temp = {};
                        temp.Product = doc.data().Product;
                        temp.Reviews = doc.data().Reviews;
                        temp.Id = doc.id;
                        if(temp.Product.Medias[0].IsImage){
                            temp.PrimaryImage = temp.Product.Medias[0].Url;
                        }
                        else{
                            temp.PrimaryImage = temp.Product.Medias[0].VideoThumbnailUrl; 
                        }
                        this.$set(this.products, this.products.length, temp);
                        this.$set(this.products, this.products.length, temp);
                        this.$set(this.products, this.products.length, temp);
                    });
                })
                .finally(() =>{
                    this.loadingProducts = false;
                })
            },
        },
        created() {
            this.GetProducts();
        },
    }
</script>

<style >
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.product-item {
  display: inline-block;
  margin-right: 10px;
}
.product-enter-active, .product-leave-active {
  transition: all 1s;
}
.product-enter, .product-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
</style>