<template>
    <div v-if="user.admin">
        <index></index>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Index from '../components/Admin/ManageVideos/Index.vue'
    export default {
        components:{
            Index
        },
        computed: {
        ...mapGetters({
          user: "user"
        })
      },
    }
</script>

<style lang="scss" scoped>

</style>