import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ManageVideos from '../views/ManageVideos.vue'
import ManageProducts from '../views/ManageProducts.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Pictures from '../views/Pictures.vue'

import Products from '../views/ProductsOverview.vue'
import store from '../store'
import Product from '../views/Product.vue'
import ContactUs from '../views/ContactUs.vue'
import Videos from '../views/VideosView.vue'
import Video from '../views/Video.vue'
import NotFound from '../views/NotFound.vue'
import AboutUs from '../views/AboutUs.vue'
import WhyUs from '../views/WhyUs.vue'
import HowToInstall from '../views/HowTo/Install.vue'
import HowToUse from '../views/HowTo/Use.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      authRequired: false,
      title: 'Chain Saf'
    }
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: ContactUs,
    meta: {
      title: 'Contact Us'
    }
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: AboutUs,
    meta: {
      title: 'About Us'
    }
  },
  {
    path: '/Videos',
    name: 'Videos',
    component: Videos,
    meta: {
      title: 'Videos'
    }
  },
  {
    path: '/Video/:videoId',
    name: 'Video',
    component: Video,
    meta: {
      title: 'Video'
    }
  },
  {
    path: '/ManageProducts',
    name: 'ManageProducts',
    component: ManageProducts,
    meta: {
      authRequired: true,
      adminRole: true,
      title: 'Manage Products'
    }
  },
  {
    path: '/ManageVideos',
    name: 'ManageVideos',
    component: ManageVideos,
    meta: {
      authRequired: true,
      adminRole: true,
      title: 'Manage Videos'
    }
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
    meta: {
      authRequired: false,
      adminRole: true,
      title: 'Login'
    }
  },
  {
    path: '/Register',
    name: 'Register',
    component: Register,
    meta: {
      authRequired: true,
      adminRole: true,
      title: 'Register'
    },
  },
  {
    
    path: '/Pictures',
    name: 'Pictures',
    component: Pictures,
    meta: {
      title: 'Pictures'
    }
  },
  {
    path: '/Products',
    name: 'Products',
    component: Products,
    meta: {
      authRequired: true,
      adminRole: true,
      title: 'Products'
    }
  },
  {
    path: '/Product/:productId',
    name: 'Product',
    component: Product,
    meta: {
      authRequired: true,
      adminRole: true,
      title: 'Product'
    },
  },
  {
    path: '/WhyUs',
    name: 'WhyUs',
    component: WhyUs,
    meta: {
      title: 'Why Us'
    },
  },
  {
    path: '/HowTo/Use',
    name: 'HowToUse',
    component: HowToUse,
    meta: {
      title: 'How To Use'
    },
  },
  {
    path: '/HowTo/Install',
    name: 'HowToInstall',
    component: HowToInstall,
    meta: {
      title: 'How To Install'
    },
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  { 
    path: '/:catchAll(.*)', 
    component: NotFound,
    name: 'NotFound'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  let currentUser = store.state.user;
  if (to.matched.some(record => record.meta.authRequired)) {
      if (currentUser.loggedIn) {
        if (to.matched.some(record => record.meta.adminRole) && currentUser.admin) {
          next()
        } 
        else{
          next({ path: '/', name: 'Home', component: Home })
        }
      } else {
        next({
          path: '/Login',
          query: {
            redirect: to.fullPath
          }
        })
      }
  
    next()  
  }
  else{
    next()
  }
});

export default router
