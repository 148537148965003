<template>
    <div>
         <!-- <v-parallax
            dark
            src="../../assets/contact-us.jpeg"
            height="300"
            class="mb-5"
        >
            <div class="mb-auto mt-5">
                <h1 >
                    About Us
                </h1>
                <p>
                    We are happy to answer any questions you have!
                    <br>
                    Email us with any questions or inquiries or contact us at 555-555-5555. 
                    <br>
                    We will reach out to you as soon as we can. We look forward to doing business with you! 
                </p>
            </div>
        </v-parallax> -->
        <h1 class="border-bottom mb-6" style="font-size:5em">
            The Story of Chainsaf
        </h1>

        <v-container>
            <v-row>
                <v-col cols="12" md="6">
                    <v-img contain src="../../assets/about-us.jpeg" max-height="500px">
                    </v-img>
                </v-col>
                <v-col cols="12" md="6" style="font-size:1.5em">
                    <p class="my-7">
                        Chainsaf is the invention of Tony Boyett. While tying down a tractor on a trailer, he 
                        realized that equipment securement was, for the most part, exactly the same as it had been for decades.
                    </p>
 
                    <p class="my-7">
                        After climbining on and off that trailer, 
                        going back and forth to the truck bed to get chains and binders, he began designing Chainsaf.
                    </p>
                    
                    <p class="my-7">
                        Years of design effort brings you the most conventient, 
                        time saving innovation of load securement in years
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>