<template>
    <div>
       <index></index>
    </div>
</template>

<script>
import Index from '../components/Admin/ManageProducts/Index.vue'
    export default {
        components:{
            Index
        }
    }
</script>

<style lang="scss" scoped>

</style>