<template>
    <div>
        <v-container>
            <v-row>
                <v-col md="6" cols="12">
                    <v-skeleton-loader
                    :loading="loadingProducts"
                    type="image"
                    height="500"
                    >
                        <v-carousel v-model="currentMediaIndex" hide-delimiters>
                            <v-carousel-item
                            v-for="(media, index) in medias"
                            :key="`image-${index}`"
                            
                            >
                            <v-img v-if="media.IsImage" :src="media.Url"
                            @click="openDialog = true"
                            style="cursor: pointer;">
                            </v-img>
                            <video v-else style="max-width: 100%; height: auto;" controls>
                                <source :src="media.Url" type="video/mp4">
                                Your browser does not support HTML video.
                            </video>
                            </v-carousel-item>
                        </v-carousel>
                        <div class="d-flex justify-space-between flex-wrap mt-3">
                           <v-img v-for="(media, index) in medias"
                            :key="`image-below-${index}`"
                            :src="media.IsImage ? media.Url : media.VideoThumbnailUrl"
                            @click="currentMediaIndex = index"
                            :class="currentMediaIndex != index ? 'nonactive-img-cara' : ''"
                            max-width="150px"
                            style="cursor: pointer;"
                            contain>
                           </v-img>
                        </div>
                    </v-skeleton-loader>
                </v-col>
                 <v-col md="6" cols="12">
                    <v-skeleton-loader
                    :loading="loadingProducts"
                    type="heading, list-item-two-line, text@3, text@3, text@3"
                    height="500"
                    >
                        <h1 class="text-left border-bottom mb-4">{{product != null ? product.Name : ''}}</h1>
                        <div class="my-8">
                            <v-container>
                                <v-row>
                                    <v-col md="6" col="12">
                                        <h2>{{product != null ? formatMoney(product.Price) : ''}}</h2>
                                    </v-col>
                                    <v-col md="6" col="12">
                                        <v-btn color="#540100" class="white--text" @click="Contact()">
                                            Contact us for sales<v-icon class="ml-2">contact_phone</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </div>
                        <p v-html="product != null ? product.Description : ''"></p>
                    </v-skeleton-loader>
                </v-col>
            </v-row>
        </v-container>

        <v-divider class="mx-16 my-5"/>

        <v-container>
            <v-row v-if="product != null">
                
                <v-col cols="12" md="4">
                    <div v-if="reviews.length > 0">
                        <v-rating
                        v-model="averageReviews"
                        length="5"
                        color="amber"
                        half-increments
                        half-icon="star_half"
                        full-icon="star"
                        empty-icon="star_outline"
                        readonly
                        size="50"
                        ></v-rating>
                        {{averageReviews}} out of 5
                    </div>
                    <div v-else>
                        No reviews have been made
                    </div>
                    <div class="d-flex justify-content-around">
                        <div class="mr-4">5 star</div>
                         <v-progress-linear :value="fiveStarReviews" class="my-auto" style="width: 75%;"></v-progress-linear>
                         <div class="my-auto ml-3"> {{fiveStarReviews}}% </div>
                    </div>
                    <div class="d-flex justify-content-around">
                        <div class="mr-4">4 star</div>
                         <v-progress-linear :value="fourStarReviews" class="my-auto" style="width: 75%;"></v-progress-linear>
                         <div class="my-auto ml-3"> {{fourStarReviews}}% </div>
                    </div>
                    <div class="d-flex justify-content-around">
                        <div class="mr-4">3 star</div>
                         <v-progress-linear :value="threeStarReviews" class="my-auto" style="width: 75%;"></v-progress-linear>
                         <div class="my-auto ml-3"> {{threeStarReviews}}% </div>
                    </div>
                    <div class="d-flex justify-content-around">
                        <div class="mr-4">2 star</div>
                         <v-progress-linear :value="twoStarReviews" class="my-auto" style="width: 75%;"></v-progress-linear>
                         <div class="my-auto ml-3"> {{twoStarReviews}}% </div>
                    </div>
                    <div class="d-flex justify-content-around">
                        <div class="mr-4">1 star</div>
                         <v-progress-linear :value="oneStarReviews" class="my-auto" style="width: 75%;"></v-progress-linear>
                         <div class="my-auto ml-3"> {{oneStarReviews}}% </div>
                    </div>
                    <v-divider class="my-4"/>
                    <h3 class="mb-4">
                        Review this product
                    </h3>
                    <p>
                        Share what your thoughts are with other customers!
                    </p>
                    <v-btn @click="$emit('start-review')" color="#540100" class="white--text">
                        Click here to start a review<v-icon class="ml-2">rate_review</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12" md="8" >
                    <h3 v-if="reviews.length == 0">
                        No reviews are available for this product
                    </h3>
                    <div v-else>
                        <h3 class="border-bottom text-left">
                            Reviews
                        </h3>
                        <div v-for="reviewIndex in reviewIndexs" :key="`review-${reviewIndex}`" class="my-4">
                            <div class="d-flex flex-wrap">
                                <h4 class="text-left mr-2 my-auto">{{reviews[reviewIndex - 1].Name}}</h4> 
                                <v-rating
                                v-model="reviews[reviewIndex - 1].StarAmount"
                                length="5"
                                full-icon="star"
                                color="amber"
                                empty-icon="star_outline"
                                class="text-left"
                                readonly
                                ></v-rating>
                            </div>
                            <p class="text-left">
                                Reviewed on {{formatDate(reviews[reviewIndex - 1].Date)}}
                            </p>
                            <p class="text-left">
                                {{reviews[reviewIndex - 1].Text}}
                            </p>
                        </div>
                        <v-btn v-if="!hideShowMore" @click="AddMoreIndex()" color="primary">
                            View more reviews
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>


        <v-dialog v-model="openDialog" width="1000px">
            <v-img v-if="product != null" contain :src="product.Medias[currentMediaIndex].Url"></v-img>
        </v-dialog>
    </div>
</template>

<script>
import firebase from 'firebase'

    export default {
        props:{
            productId: String
        },
        data() {
            return {
                product: null,
                loadingProducts: false,
                currentMediaIndex: 0,
                medias: [],
                reviews: [],
                openDialog: false,
                reviewIndexs: 0,
                hideShowMore: false
            }
        },
        computed:{
            averageReviews(){
                if(this.product == null){
                    return 0;
                }
                else{
                    return (this.lodash.sumBy(this.reviews, 'StarAmount') / this.reviews.length).toFixed(1);
                }
            },
            oneStarReviews(){
                return this.product == null || this.reviews.length == 0 ? 0 : ((this.lodash.filter(this.reviews, ({StarAmount}) => StarAmount == 1).length / this.reviews.length) * 100).toFixed(0);
            },
            twoStarReviews(){
                return this.product == null || this.reviews.length == 0 ? 0 : ((this.lodash.filter(this.reviews, ({StarAmount}) => StarAmount == 2).length / this.reviews.length) * 100).toFixed(0);
            },
            threeStarReviews(){
                return this.product == null || this.reviews.length == 0 ? 0 : ((this.lodash.filter(this.reviews, ({StarAmount}) => StarAmount == 3).length / this.reviews.length) * 100).toFixed(0);
            },
            fourStarReviews(){
                return this.product == null || this.reviews.length == 0 ? 0 : ((this.lodash.filter(this.reviews, ({StarAmount}) => StarAmount == 4).length / this.reviews.length) * 100).toFixed(0);
            },
            fiveStarReviews(){
                return this.product == null || this.reviews.length == 0 ? 0 : ((this.lodash.filter(this.reviews, ({StarAmount}) => StarAmount == 5).length / this.reviews.length) * 100).toFixed(0);
            }
        },
        methods: {
            AddMoreIndex(){
                
                var tempCounter = this.reviewIndexs + 5;
                if(tempCounter > this.reviews.length){
                    this.reviewIndexs = this.reviews.length;
                    this.hideShowMore = true;
                }
                else{
                    this.reviewIndexs = tempCounter;
                }
            },
            GetProduct(){
                this.loadingProducts = true;
                firebase.firestore().collection("Products")
                .doc(this.productId)
                .get()
                .then(response => {
                    this.product = response.data().Product;
                    this.medias = this.product.Medias;
                    this.reviews = this.lodash.reverse(response.data().Reviews);
                    if(this.reviews.length > 5){
                        this.reviewIndexs = 4;
                        this.hideShowMore = false;
                    }
                    else{
                        this.reviewIndexs = this.reviews.length;
                        this.hideShowMore = true;
                    }
                })
                .catch(() =>{
                    this.$router.push({name : 'Products'})
                })
                .finally(() =>{
                    this.loadingProducts = false;
                })
            },
            Contact(){
                this.$router.push({name: 'ContactUs', query: {productId: this.productId} })
            }
        },
        mounted() {
            if(this.productId == '' || this.productId == null){
                this.$router.push({name : 'Products'})
            }
            this.GetProduct();
            
        },
    }
</script>

<style scoped>
.nonactive-img-cara{
    opacity: 40%
}
</style>