<template>
    <div >
        <!-- <v-list three-line>
            <template v-for="(item, index) in videos">
                <v-list-item
                :key="item.Name + '-' + index"
                >
                <v-list-item-avatar>
                    <v-img :src="item.ThumbnailUrl"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title v-html="item.Name"></v-list-item-title>
                    <v-list-item-subtitle v-html="item.Description"></v-list-item-subtitle>
                </v-list-item-content>
                </v-list-item>
            </template>
        </v-list> -->
        <ul class="list-unstyled ">
            <li v-for="(item, index) in videos" :key="item.Name + '-' + index" class="my-5">
                <v-card
                    outlined
                >
                    <div class="d-flex flex-wrap">
                        <v-avatar
                        class="ma-3"
                        size="125"
                        tile
                        >
                            <v-img :src="item.ThumbnailUrl"></v-img>
                        </v-avatar>
                        <div>
                            <v-card-title
                            class="text-h5"
                            v-text="item.Name"
                            ></v-card-title>

                            <v-card-text class="text-flow" v-html="item.Description"></v-card-text>
                            <v-card-actions>
                                <v-btn
                                    class="ml-2 mt-5"
                                    outlined
                                    rounded
                                    color="#540100"
                                    small
                                    @click="WatchVideo(item.Id)"
                                >
                                    Watch Video
                                </v-btn>
                            </v-card-actions>
                        </div>
                    </div>
                </v-card>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        props:{
            videos: Array
        },
        methods: {
            WatchVideo(id){
                this.$router.push({name: 'Video', params: {videoId: id}})
            }
        },
    }
</script>

<style scoped>
.list-unstyled {
	list-style: none;
	margin-left: 0;
	padding-left: 0;
}
.text-flow{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
        -webkit-box-orient: vertical;
    }
</style>