<template>
    <div>
        <h1 class="text-center mb-6">
            Pictures
        </h1>

        <h2 class="mb-0 text-h5">
            The pictures below are an example of tying down a tractor using Chainsaf!
        </h2>


        <div  class="d-flex justify-space-around flex-wrap my-3">
            <v-img v-for="index in 3" 
                    @click="openDialog = true;currentMediaIndex = index - 1; mediaToShow = tractorMedias[index - 1]; dialogMedias = tractorMedias" 
                    :key="tractorMedias[index - 1].Url" 
                    :src="tractorMedias[index - 1].Url" 
                    class="my-4 mx-4" 
                    alt="box chain photo" 
                    style="border-radius: 1.25rem!important; cursor: pointer;"  
                    contain max-width="400" 
                    :class="$vuetify.breakpoint.lgAndUp ? 'ml-8' : 'mx-auto'">
            </v-img>
            <a class="my-auto" style="min-width:400px" href="#" @click="openDialog = true; mediaToShow = tractorMedias[0]; currentMediaIndex = 0; dialogMedias = tractorMedias">
                Click here to view more
            </a>
        </div>

        <h2 class="mb-0 mt-6 text-h5">
            The pictures below are an example of Chainsaf being used on a 35 foot flatbed!
        </h2>


        <div  class="d-flex justify-space-around flex-wrap my-3">
            <v-img v-for="index in 3" 
                    @click="openDialog = true;currentMediaIndex = index - 1; mediaToShow = flatbedMedias[index - 1]; dialogMedias = flatbedMedias" 
                    :key="flatbedMedias[index - 1].Url" 
                    :src="flatbedMedias[index - 1].IsImage ? flatbedMedias[index - 1].Url : flatbedMedias[index - 1].VideoThumbnailUrl" 
                    class="my-4 mx-2" 
                    alt="box chain photo" 
                    style="border-radius: 1.25rem!important; cursor: pointer;"  
                    contain max-width="400" 
                    :class="$vuetify.breakpoint.lgAndUp ? 'ml-8' : 'mx-auto'">
            </v-img>
            <a class="my-auto" style="min-width:400px" href="#" @click="openDialog = true; mediaToShow = flatbedMedias[0]; currentMediaIndex = 0; dialogMedias = flatbedMedias">
                Click here to view more
            </a>
        </div>

        <h2 class="mb-0 mt-6 text-h5">
            The pictures below are an example of Chainsaf being used on a 7 X 10 trailer!
        </h2>


        <div  class="d-flex justify-space-around flex-wrap my-3">
            <v-img v-for="index in 3" 
                    @click="openDialog = true;currentMediaIndex = index - 1; mediaToShow = trailerMedias[index - 1]; dialogMedias = trailerMedias" 
                    :key="trailerMedias[index - 1].Url" 
                    :src="trailerMedias[index - 1].IsImage ? trailerMedias[index - 1].Url : trailerMedias[index - 1].VideoThumbnailUrl" 
                    class="my-4 mx-2" 
                    alt="box chain photo" 
                    style="border-radius: 1.25rem!important; cursor: pointer;"  
                    contain max-width="400" 
                    :class="$vuetify.breakpoint.lgAndUp ? 'ml-8' : 'mx-auto'">
            </v-img>
            <a class="my-auto" style="min-width:400px" href="#" @click="openDialog = true; mediaToShow = trailerMedias[0]; currentMediaIndex = 0; dialogMedias = trailerMedias">
                Click here to view more
            </a>
        </div>

        <h2 class="mb-0 mt-6 text-h5">
            The pictures below are an example of two tractors being tied down using one box!
        </h2>

        <div  class="d-flex justify-space-around flex-wrap my-3">
            <v-img v-for="index in 3" 
                    @click="openDialog = true;currentMediaIndex = index - 1; mediaToShow = exampleMedias[index - 1]; dialogMedias = exampleMedias" 
                    :key="exampleMedias[index - 1].Url" 
                    :src="exampleMedias[index - 1].IsImage ? exampleMedias[index - 1].Url : exampleMedias[index - 1].VideoThumbnailUrl" 
                    class="my-4 mx-2" 
                    alt="box chain photo" 
                    style="border-radius: 1.25rem!important; cursor: pointer;"  
                    contain max-width="400" 
                    :class="$vuetify.breakpoint.lgAndUp ? 'ml-8' : 'mx-auto'">
            </v-img>
        </div>

        <v-row justify="center">
            <v-dialog v-model="openDialog">
                <v-card class="py-4">
                    <v-container>
                    <v-row>
                        <v-col ref="mediaviewer" cols="12" md="8">
                            <template v-if="mediaToShow != null && mediaToShow.IsImage">
                                <v-img  max-height="600"   contain :src="mediaToShow.Url"></v-img>
                            </template>
                            <template v-else-if="mediaToShow != null">
                                <video style="max-width: 100%; height: auto;" controls :key="mediaToShow.Url">
                                    <source :src="mediaToShow.Url" type="video/mp4">
                                    Your browser does not support HTML video.
                                </video>
                            </template>
                        </v-col>
                        <v-col cols="12" md="4">
                            <div class="d-flex justify-space-between flex-wrap mt-3 mx-5">
                                <v-img v-for="(media, index) in dialogMedias"
                                :key="`image-below-${index}`"
                                :src="media.IsImage ? media.Url : media.VideoThumbnailUrl"
                                @click="mediaToShow = media; currentMediaIndex = index; ScrollToMedia()"
                                :style="currentMediaIndex != index ? 'opacity: 40%' : ''"
                                max-width="150px"
                                class="mt-2"
                                style="cursor: pointer;"
                                contain>
                                </v-img>
                            </div>
                        </v-col>
                    </v-row>
                    </v-container>
                </v-card>
            </v-dialog>
        </v-row>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                openDialog: false,
                imageToShow: '',
                mediaToShow: null,
                currentMediaIndex: -1,
                dialogMedias: [

                ],
                tractorMedias:[
                    {
                        Url: require('@/assets/IMG_1410.jpeg'),
                        IsImage: true,
                    },
                    {
                        Url: require('@/assets/IMG_1411.jpeg'),
                        IsImage: true,
                    },
                    {
                        Url: require('@/assets/IMG_1412.jpeg'),
                        IsImage: true,
                    },
                    {
                        Url: require('@/assets/IMG_1414.jpeg'),
                        IsImage: true,
                    },
                ],
                trailerMedias:[
                    {
                        Url: require('@/assets/trailer/trailer (1).jpg'),
                        IsImage: true,
                    },
                    {
                        Url: require('@/assets/trailer/trailer (2).jpg'),
                        IsImage: true,
                    },
                    {
                        Url: require('@/assets/trailer/trailer (3).jpg'),
                        IsImage: true,
                    },
                    {
                        Url: require('@/assets/trailer/trailer (4).jpg'),
                        IsImage: true,
                    },
                    {
                        Url: require('@/assets/trailer/trailer (5).jpg'),
                        IsImage: true,
                    },
                ],
                flatbedMedias:[
                    {
                        Url: require('@/assets/Flatbed/Flatbed (1).jpg'),
                        IsImage: true,
                    },
                    {
                        Url: require('@/assets/Flatbed/Flatbed (2).jpg'),
                        IsImage: true,
                    },
                    {
                        Url: require('@/assets/Flatbed/Flatbed (3).jpg'),
                        IsImage: true,
                    },
                    {
                        Url: require('@/assets/Flatbed/Flatbed (4).jpg'),
                        IsImage: true,
                    },
                    {
                        Url: require('@/assets/Flatbed/Flatbed (5).jpg'),
                        IsImage: true,
                    },
                    {
                        Url: require('@/assets/Flatbed/Flatbed (6).jpg'),
                        IsImage: true,
                    },
                    {
                        Url: require('@/assets/Flatbed/Flatbed (7).jpg'),
                        IsImage: true,
                    },
                ],
                exampleMedias:[
                    {
                        Url: require('@/assets/ex/ex (1).jpeg'),
                        IsImage: true,
                    },
                    {
                        Url: require('@/assets/ex/ex (2).jpeg'),
                        IsImage: true,
                    },
                    {
                        Url: require('@/assets/ex/ex (3).jpeg'),
                        IsImage: true,
                    },
                   
                ]
            }
        },
        methods:{
            ScrollToMedia(){
                const el = this.$refs.mediaviewer;

                if(el){
                    el.scrollIntoView({behavior: 'smooth'});
                }
            }
        },
    }
</script>

<style >
</style>