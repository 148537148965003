<template>
    <div>
        <v-parallax
            dark
            src="../../assets/contact-us.jpeg"
            height="500"
            class="mb-5"
        >
            <div class="mb-auto mt-5">
                <h1 style="font-size:5em">
                    Contact Chainsaf
                </h1>
                <p>
                    Email us with any questions or inquiries or contact us at 
                    <br>
                    <a href="tel:512-247-8795" class="white--text" style="font-size:2em;">512-247-8795.</a>
                    <br>
                    <a href="mailto:tonyboyett@gmail.com" class="white--text" style="font-size:2em;">tonyboyett@gmail.com</a>  
                    <br>
                    A Chainsaf representative will contact you shortly to answer any questions you may have or get your Chainsaf order started.
                    <br>
                    We look forward to speaking to you!
                </p>
            </div>
        </v-parallax>

        <div class="w-50-md mx-auto w-75-all">
            <form ref="form" @submit.prevent="SendEmail">
                <v-text-field
                v-model="name"
                label="Name"
                name="from_name"
                :rules="[rules.name, rules.required]"
                :disabled="processing"
                @update:error="NameError"
                outlined
                clearable
                required
                ></v-text-field>
                
                <v-text-field
                v-model="email"
                label="Email"
                name="from_email"
                :rules="[rules.email, rules.required]"
                :disabled="processing"
                @update:error="EmailError"
                type="email"
                outlined
                clearable
                required
                ></v-text-field>

                <v-text-field
                v-model="phone"
                label="Phone Number"
                name="from_number"
                :rules="[rules.phoneNumber, rules.required]"
                :disabled="processing"
                @update:error="PhoneError"
                type="tel"
                outlined
                placeholder="555-555-5555"
                clearable
                required
                ></v-text-field>

                <v-text-field
                v-model="subject"
                label="Email Subject"
                name="from_subject"
                :rules="[rules.name, rules.required]"
                :disabled="processing"
                @update:error="SubjectError"
                outlined
                clearable
                required
                ></v-text-field>

                <v-textarea
                outlined
                label="Email Message"
                name="message"
                v-model="emailMessage"
                :rules="[rules.name, rules.required]"
                @update:error="EmailMessageError"
                required
                :disabled="processing"
                placeholder="Please write email message here"
                ></v-textarea>
                <div class="d-flex flex-wrap mb-4">
                    <div class="mr-6">
                        Verify
                    </div>  
                    <vue-recaptcha sitekey="6LcpHFgcAAAAAJtMVNnivsjYnza3uv-kMXm_xt-y" :loadRecaptchaScript="true" @verify="onVerify" @expired="onExpired"  ref="recaptcha" >
                    </vue-recaptcha>
                </div>
                <v-alert
                type="success"
                transition="scale-transition"
                v-if="success"
                >
                
                    Thank you for sending in a contact form!
                </v-alert>
                <v-alert
                type="error"
                transition="scale-transition"
                v-if="error"
                >
                    Error please refresh and try again.
                </v-alert>

                <div class="d-flex">
                    <v-btn color="#540100" @click="SendEmail" class="ml-auto white--text" :disabled="!verified || emailMessageError || subjectError || phoneError || emailError || nameError || processing">
                        Submit
                    </v-btn>
                </div>
            </form>

            <form ref="hidden_form"> 
                <input type="hidden" name="from_name" :value="name">
                <input type="hidden" name="from_number" :value="phone">
                <input type="hidden" name="from_email" :value="email">
                <input type="hidden" name="from_subject" :value="subject">
                <input type="hidden" name="message" :value="emailMessage">
            </form>
        </div>

    </div>
</template>

<script>
import firebase from 'firebase'
import VueRecaptcha from 'vue-recaptcha';
import emailjs from '@emailjs/browser';
    export default {
        components:{
            VueRecaptcha
        },
        props:{
            productId: String
        },
        data() {
            return {
                processing: false,
                verified: false,
                emailMessage : '',
                emailMessageError: true,
                phone: '',
                phoneError: true,
                subject: '',
                subjectError: true,
                name: '',
                nameError: true,
                email: '',
                emailError: true,
                success: false,
                error: false,
                rules: {
                    required: value => !!value || 'Required.',
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Invalid e-mail.'
                    },
                    phoneNumber: value => {
                        const pattern = /^[0-9]{10}$/
                        return pattern.test(value) || 'Invalid phone number.'
                    },
                    name: [val => (val || '').length > 0 || 'This field is required'],
                },
            }
        },
        watch:{
            productId(){
                if(this.productId != '' && this.productId != null){
                    firebase.firestore().collection("Products")
                    .doc(this.productId)
                    .get()
                    .then(response => {
                        this.emailMessage = "Hi I was wanting to contact you because I have an inqury about " + response.data().Product.Name + "."
                        this.subject = "Question about " + response.data().Product.Name
                    })
                }
                else{
                    this.emailMessage = "";
                    this.subject = "";
                }
            }
        },
        methods: {
            SendEmail(){
                this.processing = true;
                emailjs.sendForm('service_hmwrs1e', 'template_7sc2lyo', this.$refs.form, 'user_ynvH8QnlVaHGU0ReFZ79w')
                    .then(() => {
                       this.SendUsEmail();
                    }, (error) => {
                        this.error = true;
                        console.log('FAILED...', error.text);
                    });
            },
            SendUsEmail(){
                 emailjs.sendForm('service_hmwrs1e', 'template_8opdv81', this.$refs.hidden_form, 'user_ynvH8QnlVaHGU0ReFZ79w')
                        .then(() => {
                            this.processing = false;
                            this.success = true;
                        }, (error) => {
                            this.error = true;
                            console.log('FAILED...', error.text);
                        });
            },
            onVerify(response){
                this.verified = response;
            },
            onExpired(){
                this.verified = false;
            },
            EmailMessageError(response){
                this.emailMessageError = response;
            },
            NameError(response){
                this.nameError = response;
            },
            EmailError(response){
                this.emailError = response;
            },
            PhoneError(response){
                this.phoneError = response;
            },
            SubjectError(response){
                this.subjectError = response;
            },
        },
    }
</script>

<style scoped>
@media (min-width: 960px){
    .w-50-md{
        width:50% !important
    }
}
.w-75-all{
    width:75%
}
</style>