<template>
    <div>
        <h1 class="mb-4 mt-6 text-h6">
            The video shows you how to disconnect our boxes that was used to haul a side-by-side!
        </h1>
        <video width="400" controls="controls">
            <source src="https://firebasestorage.googleapis.com/v0/b/chainboxx-9b8f3.appspot.com/o/Videos%2FReleasing%20Chain%20Saf%20Box_5c38e85d-6157-4df0-86b7-d62621715ce0?alt=media&token=708d3058-16dd-4d94-b190-411880885dc1" type="video/mp4">
            Your browser does not support HTML video. 
        </video>
        <h1 class="mb-4 mt-6 text-h6">
            The video showcases how simple it is it would be to use our boxes to tie down a side-by-side.
        </h1>
        <video width="400" controls="controls">
            <source src="https://firebasestorage.googleapis.com/v0/b/chainboxx-9b8f3.appspot.com/o/Videos%2FTying%20Down%20a%20Side-by-Side_c3f80700-0e07-4898-8568-f718667fb482?alt=media&token=d6bad401-7e86-4a3f-8ab3-ffc6f388eec7" type="video/mp4">
            Your browser does not support HTML video. 
        </video>
        <h1 class="mb-4 text-h6">
            The video is an example of how Chainsaf can be used to tie down two tractors!
        </h1>
        <video width="400" controls="controls">
            <source src="https://firebasestorage.googleapis.com/v0/b/chainboxx-9b8f3.appspot.com/o/Videos%2FHow%20To%20Use%20Chainsaf_e3095107-f655-456f-8ad9-27f2b682ac0c?alt=media&token=ad79eb95-097e-4ea6-98b6-5519ddc34b51" type="video/mp4">
            Your browser does not support HTML video. 
        </video>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>