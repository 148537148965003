<template>
    <div class="mx-16">
        <h1 class="text-left border-bottom">
            Create Review
        </h1>

        <v-container v-if="!loadingProducts">
            <v-row>
                <v-col col="12" md="3">
                    <v-img :src="product.Medias[0].IsImage ? product.Medias[0].Url : product.Medias[0].VideoThumbnailUrl">
                    </v-img>
                </v-col>
                <v-col col="12" md="9" class="my-auto">
                    <h2 class="h4 text-left">{{product.Name}}</h2>
                </v-col>
            </v-row>
        </v-container>
        <v-divider class="my-4 mx-3"/>
        <div id="need-stars">
            <h3 class="h6 d-flex justify-content-start">
                Overall Rating
            </h3>
            <v-alert
            v-if="needStars"
            dense
            outlined
            type="error"
            >
                Please give the product a rating.
            </v-alert>
            <v-rating
            v-model="review"
            length="5"
            full-icon="star"
            empty-icon="star_outline"
            hover
            size="50"
            class="d-flex justify-content-start"
            ></v-rating>
        </div>
        <v-divider class="my-4 mx-3"/>
        <div >
            <h3 class="h6 d-flex justify-content-start">
                Name
            </h3>
            <v-text-field
            v-model="name"
            label="Name"
            :rules="rules.name"
            :disabled="processing"
            outlined
            clearable
            required
            ></v-text-field>
        </div>
        <div >
            <h3 class="h6 d-flex justify-content-start">
                Email
            </h3>
            <v-text-field
            v-model="email"
            label="Email"
            :rules="[rules.email, rules.required]"
            :disabled="processing"
            type="email"
            outlined
            clearable
            required
            ></v-text-field>
        </div>
        <div >
            <h3 class="h6 d-flex justify-content-start">
                Written Review
            </h3>
            <v-textarea
            outlined
            name="written-review"
            label="Written Review"
            v-model="textReview"
            placeholder="Please Write Written Review Here"
            ></v-textarea>
        </div>

        <div class="d-flex flex-wrap mb-4">
            <div class="mr-6">
                Verify
            </div>  
            <vue-recaptcha sitekey="6LcpHFgcAAAAAJtMVNnivsjYnza3uv-kMXm_xt-y" :loadRecaptchaScript="true" @verify="onVerify" @expired="onExpired"  ref="recaptcha" >
            </vue-recaptcha>
        </div>

        <div class="d-flex justify-content-end">
            <v-btn color="error" @click="$emit('change-page')">
                Cancel
            </v-btn>
            <v-btn color="success" class="ml-4" @click="SaveReview" :disabled="!FormValid">
                Submit Review
            </v-btn>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase'
import VueRecaptcha from 'vue-recaptcha';
    export default {
        components:{
            VueRecaptcha
        },
        props:{
            productId: String
        },
        data() {
            return {
                product: null,
                loadingProducts: false,
                review: 0,
                textReview: "",
                name: "",
                email: "",
                needStars: false,
                rules: {
                    required: value => !!value || 'Required.',
                    age: [
                        val => val < 10 || `I don't believe you!`,
                    ],
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Invalid e-mail.'
                    },
                    name: [val => (val || '').length > 0 || 'This field is required'],
                },
                processing: false,
                verified: false
            }
        },
        computed:{
            FormValid(){
                return this.name != '' && this.email != '' && this.verified;
            }
        },
        methods: {
             onVerify(response){
                this.verified = response;
            },
            onExpired(){
                this.verified = false;
            },
            GetProduct(){
                this.loadingProducts = true;
                firebase.firestore().collection("Products")
                .doc(this.productId)
                .get()
                .then(response => {
                    this.product = response.data().Product;
                })
                .catch(() =>{
                    this.$router.push({name : 'Products'})
                })
                .finally(() =>{
                    this.loadingProducts = false;
                })
            },
            SaveReview(){
                if(this.review == 0){
                    this.needStars = true;
                    const el = document.getElementById('need-stars');

                    // Use el.scrollIntoView() to instantly scroll to the element
                    el.scrollIntoView({behavior: 'smooth'});
                    return;
                }
                this.processing = true;
                this.needStars = false;
                var review = {}
                review.StarAmount = this.review;
                review.Text = this.textReview;
                review.Name = this.name;
                review.Email = this.email;
                review.Date = Date.now();
                
                if(this.review >= 4){
                    firebase.firestore().collection("Products")
                    .doc(this.productId)
                    .update({  Reviews: firebase.firestore.FieldValue.arrayUnion(review) })
                    .then(() => {
                        this.$emit('change-page')
                    })
                    .catch(() => {
                        alert("Couldn't save please refresh and try again");
                    })
                    .finally(() =>{
                        this.processing = false;
                    })
                }
                else{
                    firebase.firestore().collection("ReviewsToBeReviewed")
                    .add({  Review: review, ProductId: this.productId })
                    .then(() => {
                        this.$emit('change-page')
                    })
                    .catch(() => {
                        alert("Couldn't save please refresh and try again");
                    })
                    .finally(() =>{
                        this.processing = false;
                    })
                }
            }
        },
        mounted() {
            if(this.productId == '' || this.productId == null){
                this.$router.push({name : 'Products'})
            }
            this.GetProduct();
        },
    }
</script>

<style lang="scss" scoped>

</style>