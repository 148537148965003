<template>
    <div>
        <h1 class="text-h3">
            Sorry we couldn't find that page please try again.
        </h1>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>