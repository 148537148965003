<template>
  <div class="login">
    <h1>Login</h1>
    <img src="../../assets/logo.png" width="300px">
    <v-text-field
        label="Email"
        placeholder="Email"
        outlined
        required
        v-model="email"
        class="w-50-md mx-auto w-75-all mb-0"
    ></v-text-field>
    
    <v-text-field
        label="Password"
        placeholder="Password"
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required]"
        :type="show1 ? 'text' : 'password'"
        @click:append="show1 = !show1"
        outlined
        required
        v-model="password"
        class="w-50-md mx-auto w-75-all mb-6"
    ></v-text-field>
    <v-btn @click="login" color="blue" class="mb-5">
        Login
    </v-btn>
    <!-- <p><router-link to="/signup">
      New Here? Create a new account
    </router-link></p> -->
  </div>
</template>

<script>
  import firebase from 'firebase/app'
  import 'firebase/auth' 
  export default {
    name: 'Login',
    data: function() {
      return {
        email: '',
        password: '',
        show1: false,
        rules: {
            required: value => !!value || 'Required.',
        },
      }
    },
    methods: {
      login () {
        firebase.auth().signInWithEmailAndPassword(this.email, this.password).then((user) => {
            console.log(user);
          this.$router.replace('/')
        }).catch((err) => {
          alert(err.message)
        })
      }
    }
  }
</script>

<style scoped>
@media (min-width: 960px){
    .w-50-md{
        width:50% !important
    }
}
.w-75-all{
    width:75%
}
</style>