<template>
    <div>
        <h1 class="mt-3">
            Manage Videos
        </h1>
        <v-divider class="mx-16"></v-divider>
        <div class="d-flex justify-end mx-16 mt-5">
            <add-video @saved="GetVideos()"></add-video>
        </div>

        <div class="mx-16 d-flex justify-start mt-3">
            <div class="w-20-md">
                <v-text-field
                v-model="filter"
                label="Filter"
                outlined
                clearable
                append-icon="mdi-magnify"
                hide-details
                ></v-text-field>
            </div>
            <v-btn color="primary" class="my-auto ml-6" @click="GetVideos()">
                Refresh <v-icon>refresh</v-icon>
            </v-btn>
        </div>
        <v-alert v-if="error" type="error">
            Error please refresh and try again.
        </v-alert>
        <div class="mx-16">
            <v-data-table
            :headers="headers"
            :items="videos"
            :search="filter"
            :loading="loadingVideos"
            loading-text="Loading... Please wait"
            >
                <template v-slot:[`item.Video.ThumbnailUrl`]="{ item }">
                    <v-img :src="item.Video.ThumbnailUrl" max-width="200" contain>
                    </v-img>
                </template>
                <template v-slot:[`item.Video`]="{ item }">
                    <v-btn color="info" @click="editIndex = videos.indexOf(item); openDialog = true;">
                        Edit <v-icon>edit</v-icon>
                    </v-btn>
                </template>
                <template v-slot:[`item.Delete`]="{ item }">
                    <v-btn color="error" @click="dialogDelete = true; deleteIndex = videos.indexOf(item)">
                        Delete <v-icon>delete</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </div>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogDelete = false" :disabled="processing">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="DeleteProduct()" :disabled="processing">OK <v-progress-circular v-if="processing" indeterminate></v-progress-circular></v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-snackbar
        v-model="errorToast"
        color="error"
        top
        right
        >
        <v-icon>error_outline</v-icon> Please refresh and try again
        </v-snackbar>
        <v-snackbar
        v-model="successToast"
        color="success"
        top
        right
        >
        <v-icon>check_circle</v-icon> Success!
        </v-snackbar>

        <manage-video 
            :video="editIndex == -1 ? null : videos[editIndex].Video" 
            :openDialog="openDialog" 
            :videoId="editIndex == -1 ? '' : videos[editIndex].Id" 
            @close-dialog="openDialog = false" 
            @saved="openDialog = false">
        </manage-video>
    </div>
</template>

<script>
import AddVideo from './AddVideo.vue'
import firebase from 'firebase'
import ManageVideo from './ManageVideo.vue'

    export default {
        components:{
            AddVideo,
            ManageVideo
        },
        data() {
            return {
                dialogDelete: false,
                editIndex: -1,
                loadingVideos: false,
                videos: [],
                filter: "",
                headers:[
                    { text: 'Edit', value: "Video", sortable: false},
                    { text: 'Image', value: 'Video.ThumbnailUrl', sortable: false },
                    { text: 'Name', value: 'Video.Name' },
                    { text: 'Delete', value: 'Delete', sortable: false}
                ],
                errorToast: false,
                successToast: false,
                processing: false,
                deleteIndex: -1,
                openDialog: false,
            }
        },
        methods:{
            async GetVideos(){
                this.loadingVideos = true;
                this.videos = []
                firebase.firestore().collection("Videos")
                .get()
                .then(response => {
                    response.forEach(doc => {
                        var temp = {};
                        temp.Video = doc.data().Video;
                        temp.Id = doc.id;
                        this.$set(this.videos, this.videos.length, temp);
                    });
                })
                .finally(() =>{
                    this.loadingVideos = false;
                })
            },
            UpdateVideos(){

            },
            DeleteProduct(){
                this.processing = true;
                this.error = false;
                this.errorToast = false;
                this.successToast = false;

                firebase.firestore().collection("Videos")
                .doc(this.videos[this.deleteIndex].Id)
                .delete()
                .then(() => {
                    this.DeleteMediaFromStorage(this.videos[this.deleteIndex].Video.MediaName);
                    this.DeleteMediaFromStorage(this.videos[this.deleteIndex].Video.VideoThumbnailName)
                    this.successToast = true;
                    this.$delete(this.videos, this.deleteIndex)
                    this.dialogDelete = false;
                    firebase.firestore().collection("Counters")
                    .doc('Counter')
                    .update("VideoCounter", firebase.firestore.FieldValue.increment(-1))
                })
                .catch(() => {
                    this.errorToast = true;
                    this.error = true;
                })
                .finally(() =>{
                    this.processing = false;
                })
            },
            DeleteMediaFromStorage(name){
                const store = firebase.storage().ref();
                // Depending on which db you use and how you store, you get the filePath and delete it:
                store.child(`Videos/${name}`).delete();
            }
        },
        mounted() {
            this.GetVideos();
        },
    }
</script>

<style lang="scss" scoped>

</style>