<template>
    <div>
        <index></index>
    </div>
</template>

<script>
import Index from '../components/WhyUs/Index.vue'
    export default {
        components:{
            Index
        }
    }
</script>

<style lang="scss" scoped>

</style>