<template>
    <div class="pt-4">
        <transition name="fade" mode="out-in">
            <index v-if="!onReviewPage" key="prodcutPage" :productId="productId" @start-review="onReviewPage = true">
            </index>
            <review-page v-else key="productReviewPage" :productId="productId" @change-page="onReviewPage = false" >
            </review-page>
        </transition>
    </div>
</template>

<script>
import Index from '../components/Product/Index.vue'
import ReviewPage from '../components/Product/Review.vue'
    export default {
        components:{
            Index,
            ReviewPage
        },
        data() {
            return {
                productId: this.$route.params.productId,
                onReviewPage: false
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>