<template>
    <div class="mx-16">
        <div class="row">
             <v-skeleton-loader
            :loading="loadingVideo"
            type="heading"
            class="d-flex justify-center my-5 col-12"
            >
                <h1 class="mb-8 mt-4">
                    {{video != null ? video.Name : ''}}
                </h1>
            </v-skeleton-loader>

            <v-skeleton-loader
            :loading="loadingVideo"
            type="image"
            height="250px"
            max-width="50%"
            class="col-12 mx-auto mb-4">
            
                <div style="max-width: 500px" class="mx-auto">
                    <vue-core-video-player v-if="video != null" :key="video.VideoUrl" :src="video.VideoUrl"></vue-core-video-player>
                </div>
            </v-skeleton-loader>
            <v-skeleton-loader
            :loading="loadingVideo"
            type="text@3, text@3, text@3"
            height="500"
            class="col-12"
            >
                <div>
                        <p v-if="video != null" v-html="video.Description" class="my-8 text-left"></p>
                </div>
            </v-skeleton-loader>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase'

    export default {
        props:{
            videoId: String
        },
        data() {
            return {
                video: null,
                loadingVideo: false,
                blah: null,
                ready: false
            }
        },
        methods: {
            async GetVideo(){
                this.loadingVideo = true;
                firebase.firestore().collection("Videos")
                .doc(this.videoId)
                .get()
                .then(response => {
                    this.video = response.data().Video;
                })
                .catch(() =>{
                    this.$router.push({name : 'Videos'})
                })
                .finally(() =>{
                    this.loadingVideo = false;
                })
            },
           
        },
        mounted() {
            if(this.videoId == '' || this.videoId == null){
                this.$router.push({name : 'Videos'})
            }
            this.GetVideo();
        },
    }
</script>

<style lang="scss" scoped>

</style>