<template>
    <div>
         <v-dialog
            v-model="openDialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            >
            <v-card>
                <v-toolbar
                dark
                color="primary"
                >
                    <v-btn
                        icon
                        dark
                        @click="$emit('close-dialog')"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Manage Product</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                        dark
                        text
                        @click="Save()"
                        :disabled="product.Name == '' || product.Description == '' || product.Medias.length == 0"
                        >
                        Save <v-progress-circular v-if="processing" indeterminate></v-progress-circular>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-alert v-if="error" type="error">
                    Error please refresh and try again.
                </v-alert>
                <v-container>
                    <v-row>
                       <v-col md="4" cols="12">
                           <v-text-field
                            v-model="product.Name"
                            label="Product Name"
                            outlined
                            :disabled="processing"
                            clearable
                            required
                            ></v-text-field>
                       </v-col> 
                       <v-col md="4" cols="12">
                           <v-text-field
                            v-model="product.Price"
                            label="Product Price"
                            prefix="$"
                            type="Number"
                            :disabled="processing"
                            min="0.01" 
                            step="0.01"
                            outlined
                            clearable
                            required
                            ></v-text-field>
                       </v-col>
                       <v-col md="4" cols="12">
                            <p>
                               Product Description
                           </p>
                            <quill-editor v-model="product.Description"></quill-editor>
                       </v-col> 
                       <v-col md="3" cols="12">
                           <v-file-input
                                multiple
                                label="File input"
                                v-model="tempFiles"
                                @change="PreviewMedia"
                                :disabled="processing"
                                accept="image/*,video/*"
                            ></v-file-input>
                            <p>
                                Please upload any photos you would like to use for the product. The first photo will be used as the primary photo.
                                Please add a quick description to what the photo is for accessability.
                            </p>
                       </v-col>
                       <v-col md="9" cols="12">
                           <v-list style="height: 500px; overflow:scroll;">
                                <v-list-item
                                    v-for="(media, index) in product.Medias" :key="index"
                                >
                                    <v-list-item-content>
                                        <v-tooltip bottom class="my-3" v-if="index != 0">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                color="primary"
                                                v-bind="attrs"
                                                :disabled="processing"
                                                v-on="on"
                                                @click="MoveMediaUp(index)"
                                                >
                                                    <v-icon>
                                                        arrow_upward
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Move Media Up</span>
                                        </v-tooltip>

                                        <v-tooltip bottom class="my-3" v-if="index != (product.Medias.length - 1)">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                color="primary"
                                                v-bind="attrs"
                                                :disabled="processing"
                                                v-on="on"
                                                @click="MoveMediaDown(index)"
                                                >
                                                    <v-icon>
                                                        arrow_downward
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Move Media Down</span>
                                        </v-tooltip>
                                    </v-list-item-content>

                                   

                                    <v-list-item-content size="120"  tile>
                                        <v-img v-if="media.IsImage" :src="media.Url" max-height="200" contain></v-img>
                                        <video v-else width="400" controls>
                                            <source :src="media.Url" type="video/mp4">
                                            Your browser does not support HTML video.
                                        </video>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-text-field
                                        label="Image Description"
                                        outlined
                                        clearable
                                        required
                                        :disabled="processing || !media.IsImage"
                                        class="my-auto"
                                        ></v-text-field>
                                    </v-list-item-action>
                                     <v-list-item-action>
                                        <v-btn color="danger" class="my-auto" @click="DeleteMedia(index)" :disabled="processing">
                                            <v-icon>
                                                delete
                                            </v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                       </v-col>
                    </v-row>
                </v-container>
                
            </v-card>
        </v-dialog>
        <v-snackbar
        v-model="errorToast"
        color="error"
        top
        right
        >
        <v-icon>error_outline</v-icon> Please refresh and try again
        </v-snackbar>
        <v-snackbar
        v-model="successToast"
        color="success"
        top
        right
        >
        <v-icon>check_circle</v-icon> Success!
        </v-snackbar>
    </div>
</template>

<script>
import firebase from 'firebase'
    export default {
        props:{
            product: Object,
            openDialog: Boolean,
            productId: String
        },
        data() {
            return {
                tempFiles: [],
                processing: false,
                deleteMediaNames:[],
                error: false,
                errorToast: false,
                successToast: false
            }
        },
        methods: {
            async PreviewMedia(){
                for(var x = 0; x < this.tempFiles.length; x++){
                    var tempObject = new Object;
                    tempObject.IsImage = this.IsImage(this.tempFiles[x]);
                    tempObject.Url = URL.createObjectURL(this.tempFiles[x]);
                    //     // const cover = await this.GetVideoCover(this.files[x], 1.5);
                    //     // tempObject.Url = URL.createObjectURL(cover); 
                    tempObject.Description = "";
                    tempObject.File = this.tempFiles[x];
                    this.$set(this.product.Medias, this.product.Medias.length, tempObject)
                }
                this.tempFiles = [];
            },
            MoveMediaUp(index){
                var temp = this.product.Medias[index - 1];
                var temp2 = this.product.Medias[index]
                this.$set(this.product.Medias, index, temp)
                this.$set(this.product.Medias, index - 1, temp2)
            },
            MoveMediaDown(index){
                var temp = this.product.Medias[index + 1];
                var temp2 = this.product.Medias[index]
                this.$set(this.product.Medias, index, temp)
                this.$set(this.product.Medias, index + 1, temp2)
            },
            DeleteMedia(index){
                this.$delete(this.product.Medias, index);
                this.$set(this.deleteMediaNames, this.deleteMediaNames.length, this.product.Medias[index].MediaName)
                if(!this.product.Medias[index].IsImage){
                    this.$set(this.deleteMediaNames, this.deleteMediaNames.length, this.product.Medias[index].VideoThumbnailName)
                }
            },
            async Save(){
                this.processing = true;
                this.error = false;
                this.errorToast = false;
                this.successToast = false;
                for(var x = 0; x < this.product.Medias.length; x++){
                    if(this.product.Medias[x].File != null){
                        if(!this.product.Medias[x].IsImage){
                            var result = await this.UploadMedias(this.product.Medias[x].VideoThumbnailBlob);
                            this.$set(this.product.Medias[x], 'VideoThumbnailUrl', result.Url);
                            this.$set(this.product.Medias[x], 'VideoThumbnailName', result.MediaName)
                            this.$delete(this.product.Medias[x], 'VideoThumbnailBlob')
                        }
                        var res = await this.UploadMedias(this.product.Medias[x].File, x);
                        this.product.Medias[x].Url = res.Url;
                        this.product.Medias[x].MediaName = res.MediaName;
                        this.$delete(this.product.Medias[x], 'File')
                    }
                }

                firebase.firestore().collection("Products")
                .doc(this.productId)
                .update({  Product: this.product })
                .then(() => {
                    for(var x = 0; x < this.deleteMediaNames.length; x++){
                        this.DeleteMediaFromStorage(this.deleteMediaNames[x]);
                    }
                    this.successToast = true;
                    this.$emit('saved')
                })
                .catch(() => {
                    this.errorToast = true;
                    this.error = true;
                })
                .finally(() =>{
                    this.processing = false;
                })
            },
            UploadMedias(imgData){
                var guid = this.GetGuid();
                return new Promise((resolve) => {const storageRef=firebase.storage().ref(`ProductMedias/${this.product.Name}_${guid}`).put(imgData);
                storageRef.on(`state_changed`,snapshot=>{
                this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
                    }, error=>{console.log(error.message)},
                    ()=>{this.uploadValue=100;
                    storageRef.snapshot.ref.getDownloadURL()
                        .then((url)=>{
                            //this.$set(this.product.Medias[index], 'MediaName', `${this.product.Name}_${guid}`)
                            resolve({Url: url, MediaName: `${this.product.Name}_${guid}`});
                        });
                    }      
                );
               });
            },
            DeleteMediaFromStorage(mediaName){
                const store = firebase.storage().ref();
                // Depending on which db you use and how you store, you get the filePath and delete it:
                store.child(`ProductMedias/${mediaName}`).delete();
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>