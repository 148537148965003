<template>
  <div id="app"  style="margin-top: 0px; margin-bottom: 0px">
    <v-app style="margin-top: 0px; margin-bottom: 0px">
        <v-navigation-drawer
          v-model="drawer"
          :right="false"
          :clipped="$vuetify.breakpoint.lgAndUp"
          fixed
          temporary
          color="#540100"
        >
          <v-list
            dense
          >
            <v-list-item two-line class="px-0">
              <v-list-item-content>
                <v-img contain max-height="100px"  alt="company logo" :src="require('../src/assets/logo.png')"></v-img>
                <v-list-item-title class="text-change">Chain Boxx</v-list-item-title>
                <v-list-item-subtitle class="text-change" v-if="user.loggedIn"> 
                  Welcome {{user.data.displayName}}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
  
            <v-divider></v-divider>

            <v-list-item link to="/">
              <v-list-item-icon>
                <v-icon class="text-change">home</v-icon>
              </v-list-item-icon>
  
              <v-list-item-content>
                <v-list-item-title class="text-change">
                    Home
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/Pictures">
              <v-list-item-icon>
                <v-icon class="text-change">collections</v-icon>
              </v-list-item-icon>
  
              <v-list-item-content>
                <v-list-item-title class="text-change">
                    Pictures
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/Videos">
              <v-list-item-icon>
                <v-icon class="text-change">movie</v-icon>
              </v-list-item-icon>
  
              <v-list-item-content>
                <v-list-item-title class="text-change"> 
                    Videos
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/WhyUs">
              <v-list-item-icon>
                <v-icon class="text-change">help_center</v-icon>
              </v-list-item-icon>
  
              <v-list-item-content>
                <v-list-item-title class="text-change"> 
                    Why Chainsaf
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/ContactUs">
              <v-list-item-icon>
                <v-icon class="text-change">contact_phone</v-icon>
              </v-list-item-icon>
  
              <v-list-item-content>
                <v-list-item-title class="text-change">
                    Contact Chainsaf
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/AboutUs">
              <v-list-item-icon>
                <v-icon class="text-change">info</v-icon>
              </v-list-item-icon>
  
              <v-list-item-content>
                <v-list-item-title class="text-change">
                    The Story Of Chainsaf
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="'How To'" class="text-change"></v-list-item-title>
                </v-list-item-content>
              </template>

              <template v-slot:prependIcon class="text-change">
                <v-icon class="text-change">feed</v-icon>
              </template>

              
              <v-list-item link to="/HowTo/Use">
                <v-list-item-content>
                  <v-list-item-title v-text="'Use Box'" class="text-change"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item link to="/HowTo/Install">
                <v-list-item-content>
                  <v-list-item-title v-text="'Install Box'" class="text-change"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>


             <v-list-group v-if="user.loggedIn && user.admin" prepend-icon="admin_panel_settings">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="'Admin'" class="text-change"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item link to="/ManageProducts">
                <v-list-item-content>
                  <v-list-item-title v-text="'Manage Products'" class="text-change"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item link to="/ManageVideos">
                <v-list-item-content>
                  <v-list-item-title v-text="'Manage Videos'" class="text-change"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <!-- <v-list-item v-if="user.loggedIn && user.admin" link to="/Admin">
              <v-list-item-icon>
                <v-icon>admin_panel_settings</v-icon>
              </v-list-item-icon>
  
              <v-list-item-content>
                <v-list-item-title>
                  Manage products
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title>
                  Manage products
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->

            <!-- <v-list-item
          v-for="child in item.items"
          :key="child.title"
        >
          <v-list-item-content>
            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

            <v-list-item v-if="user.loggedIn" link @click.prevent="SignOut">
              <v-list-item-icon>
                <v-icon class="text-change">logout</v-icon>
              </v-list-item-icon>
  
              <v-list-item-content>
                <v-list-item-title class="text-change">
                    Log Out
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </v-list>
      </v-navigation-drawer>
      <v-app-bar app color="#540100" :clipped-left="$vuetify.breakpoint.lgAndUp" >
        <v-app-bar-nav-icon v-if="!$vuetify.breakpoint.lgAndUp" @click="drawer = !drawer" ></v-app-bar-nav-icon>

        <router-link to="/"><img style="width:75px; padding-right:10px" alt="company logo" :src="require('../src/assets/logo.png')"></router-link>
        <v-toolbar-title class="mr-6"><router-link to="/" style="text-decoration:none; color:white;"><strong>Chain Saf</strong></router-link></v-toolbar-title>



        <v-btn class="mx-6 text-change" v-if="$vuetify.breakpoint.lgAndUp" link to="/Pictures" color="#540100">
          Pictures
        </v-btn>
        <v-btn class="mx-6 text-change"  v-if="$vuetify.breakpoint.lgAndUp" link to="/Videos" color="#540100">
          Videos
        </v-btn>
        <v-btn class="mx-6 text-change"  v-if="$vuetify.breakpoint.lgAndUp" link to="/WhyUs" color="#540100">
          Why Chainsaf
        </v-btn>
        <v-btn class="mx-6 text-change" v-if="$vuetify.breakpoint.lgAndUp" link to="/ContactUs" color="#540100">
          Contact Chainsaf
        </v-btn>
        <v-btn class="mx-6 text-change" v-if="$vuetify.breakpoint.lgAndUp" link to="/AboutUs" color="#540100">
          The Story Of Chainsaf
        </v-btn>

        <v-menu v-if="$vuetify.breakpoint.lgAndUp" :offset-x="true">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-6 text-change"
              v-bind="attrs"
              v-on="on"
              color="#540100"
            >
              How To <v-icon>arrow_drop_down</v-icon>
            </v-btn>
          </template>
          <v-list>
           <v-list-item link to="/HowTo/Use">
              <v-list-item-content >
                <v-list-item-title v-text="'Use Box'"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link to="/HowTo/Install">
              <v-list-item-content >
                <v-list-item-title v-text="'Install Box'"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu  v-if="$vuetify.breakpoint.lgAndUp && user.loggedIn && user.admin" :offset-x="true">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="mx-6 text-change"
              color="#540100"
            >
              Admin <v-icon>arrow_drop_down</v-icon>
            </v-btn>
          </template>
          <v-list>
           <v-list-item link to="/ManageProducts">
              <v-list-item-content>
                <v-list-item-title v-text="'Manage Products'"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link to="/ManageVideos">
              <v-list-item-content>
                <v-list-item-title v-text="'Manage Videos'"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn v-if="user.loggedIn && $vuetify.breakpoint.lgAndUp" link @click.prevent="SignOut" class="text-change" color="#540100">
          Log Out
        </v-btn>
        <!-- <v-btn icon>
          <v-icon>shopping_cart</v-icon>
        </v-btn> -->
      </v-app-bar>

        <!-- Sizes your content based upon application components -->
      <v-main>
        <v-btn
          small
          v-show="true"
          fab
          x-large
          fixed
          bottom
          link 
          to="/ContactUs"
          right
          color="#540100"
        >
          <v-icon>phone</v-icon>
        </v-btn>

        <!-- Provides the application the proper gutter -->
        <v-container fluid style="padding: 0px">
          <transition name="fade" mode="out-in">
          <!-- If using vue-router -->
          <router-view></router-view>
          </transition>
        </v-container>
      </v-main>

      <chain-footer :loggedIn="user.loggedIn" class="mt-4"/>
    </v-app>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import firebase from "firebase";
import ChainFooter from './views/Footer.vue'
export default {
  name: 'App',
  components:{
    ChainFooter
  },
   data () {
        return {
          drawer: false,
          items: [
            { title: 'Home', icon: 'home', routerView: '/' },
            { title: 'Login', icon: 'login', routerView: '/Login' },
          ],
          right: true,
          miniVariant: false,
          expandOnHover: false,
          background: false,
        }
      },
      methods: {
        miniUpdate(update){
          this.miniVariant = update;
        },
        SignOut(){
          firebase
          .auth()
          .signOut()
          .then(() => {
            this.$router.push("/");
          });
        }
      },
      computed: {
        bg () {
          return this.background ? 'https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg' : undefined
        },
        ...mapGetters({
          user: "user"
        })
      },
      watch: {
      '$route':{
        // eslint-disable-next-line no-unused-vars
        handler: (to, from) => {
          document.title = to.meta.title || 'Your Website'
        },
         immediate: true
      }
    },
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.text-change{
  color:white !important;
}

.theme--light.v-icon,
.theme--dark.v-icon {
    color: white !important;
}

.cs-primary-color{
  color: #540100;
}

.text-black-border{
  -webkit-text-stroke-width: .25px;
  -webkit-text-stroke-color: black;
}

</style>
