<template>
    <div>
        <h1 class="mt-3">
            Manage Products
        </h1>
        <v-divider class="mx-16"></v-divider>

        <div class="d-flex justify-end mx-16 mt-5">
            <add-product @saved="GetProducts()"></add-product>
        </div>

        <div class="mx-16 d-flex justify-start mt-3">
            <div class="w-20-md">
                <v-text-field
                v-model="filter"
                label="Filter"
                outlined
                clearable
                append-icon="mdi-magnify"
                hide-details
                ></v-text-field>
            </div>
            <v-btn color="primary" class="my-auto ml-6" @click="GetProducts">
                Refresh <v-icon>refresh</v-icon>
            </v-btn>
        </div>
        <v-alert v-if="error" type="error">
            Error please refresh and try again.
        </v-alert>
        <div class="mx-16">
            <v-data-table
            :headers="headers"
            :items="products"
            :search="filter"
            :loading="loadingProducts"
            loading-text="Loading... Please wait"
            >
                <template v-slot:[`item.PrimaryImage`]="{ item }">
                    <v-img :src="item.PrimaryImage" max-width="200" contain>
                    </v-img>
                </template>
                <template v-slot:[`item.Product.Price`]="{ item }">
                    {{formatMoney(item.Product.Price)}}
                </template>
                <template v-slot:[`item.Product`]="{ item }">
                    <v-btn color="info" @click="EditProduct(item)">
                        Edit <v-icon>edit</v-icon>
                    </v-btn>
                </template>
                <template v-slot:[`item.Delete`]="{ item }">
                    <v-btn color="error" @click="dialogDelete = true; product = item; deleteIndex = products.indexOf(item)">
                        Delete <v-icon>delete</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </div>
        <manage-product :product="product.Product == null ? product : product.Product" :openDialog="openDialog" :productId="product.Id" @close-dialog="openDialog = false" @saved="UpdateProduct()"> 
        </manage-product>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogDelete = false" :disabled="processing">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="DeleteProduct()" :disabled="processing">OK <v-progress-circular v-if="processing" indeterminate></v-progress-circular></v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-snackbar
        v-model="errorToast"
        color="error"
        top
        right
        >
        <v-icon>error_outline</v-icon> Please refresh and try again
        </v-snackbar>
        <v-snackbar
        v-model="successToast"
        color="success"
        top
        right
        >
        <v-icon>check_circle</v-icon> Success!
        </v-snackbar>
    </div>
</template>

<script>
import AddProduct from './AddProduct.vue'
import ManageProduct from './ManageProduct.vue'
import firebase from 'firebase'
    export default {
        components:{
            AddProduct,
            ManageProduct
        },
        data() {
            return {
                filter: "",
                headers:[
                    { text: 'Edit', value: "Product", sortable: false},
                    { text: 'Image', value: 'PrimaryImage', sortable: false },
                    { text: 'Name', value: 'Product.Name' },
                    { text: 'Price', value: 'Product.Price' },
                    { text: 'Delete', value: 'Delete', sortable: false}
                ],
                products:[],
                loadingProducts: false,
                openDialog: false,
                product: {
                    Name: "",
                    Images: [],
                    Price: 0,
                    Description: ""
                },
                dialogDelete: false,
                deleteIndex: -1,
                error: false,
                errorToast: false,
                successToast: false,
                processing: false
            }
        },
        methods: {
            async GetProducts(){
                this.loadingProducts = true;
                this.products = []
                firebase.firestore().collection("Products")
                .get()
                .then(response => {
                    response.forEach(doc => {
                        var temp = {};
                        temp.Product = doc.data().Product;
                        temp.Id = doc.id;
                        if(temp.Product.Medias[0].IsImage){
                            temp.PrimaryImage = temp.Product.Medias[0].Url;
                        }
                        else{
                            temp.PrimaryImage = temp.Product.Medias[0].VideoThumbnailUrl; 
                        }
                        this.$set(this.products, this.products.length, temp);
                    });
                })
                .finally(() =>{
                    this.loadingProducts = false;
                })
            },
            EditProduct(item){
                this.product = item;
                this.openDialog = true;
            },
            UpdateProduct(){
                //this.product.PrimaryImage = this.product.Product.Medias[0].Url;
                if(this.product.Product.Medias[0].IsImage){
                    this.product.PrimaryImage = this.product.Product.Medias[0].Url;
                }
                else{
                    this.product.PrimaryImage = this.product.Product.Medias[0].VideoThumbnailUrl; 
                }
                this.openDialog = false;
            },
            DeleteProduct(){
                this.processing = true;
                this.error = false;
                this.errorToast = false;
                this.successToast = false;

                firebase.firestore().collection("Products")
                .doc(this.product.Id)
                .delete()
                .then(() => {
                    for(var x = 0; x < this.products[this.deleteIndex].Product.Medias.length;x++){
                        this.DeleteMediaFromStorage(this.products[this.deleteIndex].Product.Medias[x].MediaName);
                        if(!this.products[this.deleteIndex].Product.Medias[x].IsImage){
                            this.DeleteMediaFromStorage(this.products[this.deleteIndex].Product.Medias[x].VideoThumbnailName)
                        }
                    }
                    this.successToast = true;
                    this.$delete(this.products, this.deleteIndex)
                    this.dialogDelete = false;
                    firebase.firestore().collection("Counters")
                    .doc('Counter')
                    .update("ProductCounter", firebase.firestore.FieldValue.increment(-1))
                })
                .catch(() => {
                    this.errorToast = true;
                    this.error = true;
                })
                .finally(() =>{
                    this.processing = false;
                })
            },
            DeleteMediaFromStorage(mediaName){
                const store = firebase.storage().ref();
                // Depending on which db you use and how you store, you get the filePath and delete it:
                store.child(`ProductMedias/${mediaName}`).delete();
            }

        },
        mounted() {
            this.GetProducts();
        },
    }
</script>

<style  scoped>
/*Width*/
@media (min-width: 768px) {
    .w-50-md {
        width: 50% !important;
    }
    .w-75-md {
        width: 75% !important;
    }
    .w-20-md {
        width: 20% !important;
    }
}
</style>