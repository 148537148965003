<template>
  <div class="home">
    <index v-if="true"></index>
    <div v-else >
      <router-link to="/Login"> Click here to login</router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Index from '../components/HomePage/Index.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  components: {
    Index
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  }
}
</script>
