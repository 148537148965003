<template>
    <div class="vue-tempalte">
        <form @submit.prevent="userRegistration">

            <h1>Register</h1>
            <img src="../../assets/company-logo.png" width="300px">
            <v-text-field
                label="Name"
                placeholder="Name"
                outlined
                required
                v-model="user.name"
                class="w-50-md mx-auto w-75-all mb-0"
            ></v-text-field>
            <v-text-field
                label="Email"
                placeholder="Email"
                outlined
                required
                v-model="user.email"
                class="w-50-md mx-auto w-75-all mb-0"
            ></v-text-field>
            <v-text-field
                label="Password"
                placeholder="Password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required]"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
                outlined
                required
                v-model="user.password"
                class="w-50-md mx-auto w-75-all mb-6"
            ></v-text-field>

            <v-btn type="submit" color="blue" >
                Sign Up
            </v-btn>

            <p class="forgot-password text-center">
                Already registered 
                <router-link :to="{name: 'Login'}">sign in?</router-link>
            </p>
        </form>
    </div>
</template>


<script>
import firebase from "firebase";

export default {
  data() {
    return {
        user: {
            name: '',
            email: '',
            password: '',
        },
        show1: false,
        rules: {
            required: value => !!value || 'Required.',
        },
    };
  },
  methods: {
    userRegistration() {
      firebase
      .auth()
      .createUserWithEmailAndPassword(this.user.email, this.user.password)
      .then((res) => {
        res.user
          .updateProfile({
            displayName: this.user.name
          })
          .then(() => {
            this.$router.push('/login')
          });
      })
      .catch((error) => {
         alert(error.message);
      });
    }
  }
};
</script>

<style scoped>
@media (min-width: 960px){
    .w-50-md{
        width:50% !important
    }
}
.w-75-all{
    width:75%
}
</style>