<template>
    <div>
        <index :videoId="videoId"></index>
    </div>
</template>

<script>
import Index from '../components/Video/Index.vue'
    export default {
        components:{
            Index
        },
        data() {
            return {
                videoId: this.$route.params.videoId,
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>