<template>
    <div>
        <!-- <v-parallax
            src="../../assets/Chainsaf(2dark).jpg"
            height="500"
        > -->
        <div class="hero">
            <div class="pt-8 mx-auto">
            <h1 class="text-h1" style="word-wrap: break-word;">
                Chainsaf
            </h1>
            <h2 class="text-h2 text-center text-font mt-7 cs-primary-text" style="-webkit-text-stroke-width: 1px;-webkit-text-stroke-color: white;">
                (Patent Pending)
            </h2>
            <h2 class="text-h3 text-center text-font mt-7" style="color:white;">
                The Modern Way To Secure Equiptment And Loads
            </h2>
            <div class="d-flex justify-center mt-3">
                <v-btn class="mx-3 white--text" link to="/Pictures" color="#540100" >
                    Pictures
                </v-btn>
                <v-btn class="mx-3 white--text" link to="/ContactUs" color="#540100">
                    Contact Us
                </v-btn>
            </div>
            </div>
            </div>
        <!-- </v-parallax> -->

        <!-- <div class="background-photo-properties background-photo my-5">
            <h1 class="my-auto text-h6 change-black" style="color:black;"> -->
                <!-- <v-container class="testing"> -->
                    <v-parallax src="../../assets/Chainsaf(4blur).jpg" :height="$vuetify.breakpoint.lgAndUp ? '500' : '775'" >

                    <v-row no-gutters style="height:inherit;">
                        <v-col cols="12" md="6" class="my-auto">
                            <!-- <div v-observe-visibility="{callback: firstVisibilityChanged,throttle: 200,}" class="from-left slide-in" :class="firstIsVisible ? 'show' : ''">
                                <div class="slide-in-content"> -->
                                    <div>
                                    <h2 style="-webkit-text-stroke-width: 1px;-webkit-text-stroke-color: white;" class="text-h2 mb-5 cs-primary-text" :class="$vuetify.breakpoint.lgAndUp ? 'text-right mr-8' : 'mx-auto'">
                                        Why Use Chain Saf?
                                    </h2>
                                    <ul style="display: inline-block;text-align:left;" :style="$vuetify.breakpoint.lgAndUp ? 'float: right' : ''" :class="$vuetify.breakpoint.lgAndUp ? 'mr-8' : ''">
                                        <li class="text-h4">
                                            No Carrying Chains From Truck
                                        </li>
                                        <li class="text-h4">
                                            Secure Storage & Point-Of-Pull
                                        </li>
                                        <li class="text-h4">
                                            Save Time & Energy
                                        </li>
                                        <li class="text-h4">
                                            Industrial Level Manufacturing
                                        </li>
                                        <li class="text-h4">
                                            American Made
                                        </li>
                                        <li class="text-h4">
                                            <a @click="$router.push({name: 'WhyUs'})" style="color:white;">
                                                <u>Click Here to Read More</u>
                                            </a>
                                        </li>
                                    </ul>  
                                    <!-- <h2 class="text-h5 my-5">
                                        ChainSaf’s unique design and installation allows for storing chains or straps and securement to 
                                        a point of pull without ever leaving the trailer.  Further, ChainSaf’s rugged construction allows 
                                        installation flush with the deck of the trailer and further allows equipment or vehicles to be driven right over it.  
                                    </h2>
                                    <h2 class="text-h5 my-5">
                                        When you consider that a 20 foot half inch chain weighs easily 40 pounds and that securing a peice of equipment requires 
                                        at least 4 of these, the unique storage of the ChainSaf saves dragging 160 of pounds of chain between truck and trailer. 
                                    </h2>
                                    <h2 class="text-h5 my-5">
                                        It’s size is designed for  both the chain and the necessary rigging to remain within easy reach right on the trailer and 
                                        excess chain tucks neatly underneath for safe and convenient storage while transporting equipment. 
                                    </h2>  -->
                                </div>  
                            <!-- </div> -->
                        </v-col>
                        <v-col cols="12" md="6" class="py-0 my-auto">
                            <v-img src="../../assets/Chainsaf(4).jpg" alt="box chain photo" style="border-radius: 1.25rem!important;"  contain max-width="400" :class="$vuetify.breakpoint.lgAndUp ? 'ml-8' : 'mx-auto'">
                            </v-img>
                        </v-col>
                    </v-row>
                    </v-parallax>
                <!-- </v-container> -->
            <!-- </h1>
        </div> -->

        <div style="min-height: 400px;" class="d-flex justify-space-around flex-wrap">
            <v-img @click="openDialog = true;imageToShow = require('@/assets/FP/FP (1).jpeg')" class="my-4" src="../../assets/FP/FP (1).jpeg" alt="box chain photo" style="border-radius: 1.25rem!important; cursor: pointer;"  contain max-width="400" :class="$vuetify.breakpoint.lgAndUp ? 'ml-8' : 'mx-auto'">
            </v-img>
            <v-img @click="openDialog = true;imageToShow = require('@/assets/FP/FP (2).jpeg')" class="my-4" src="../../assets/FP/FP (2).jpeg" alt="box chain photo" style="border-radius: 1.25rem!important; cursor: pointer;"  contain max-width="400" :class="$vuetify.breakpoint.lgAndUp ? 'ml-8' : 'mx-auto'">
            </v-img>
            <v-img @click="openDialog = true;imageToShow = require('@/assets/FP/FP (3).jpeg')" class="my-4" src="../../assets/FP/FP (3).jpeg" alt="box chain photo" style="border-radius: 1.25rem!important; cursor: pointer;"  contain max-width="400" :class="$vuetify.breakpoint.lgAndUp ? 'ml-8' : 'mx-auto'">
            </v-img>
            <v-img @click="openDialog = true;imageToShow = require('@/assets/FP/FP (4).jpeg')" class="my-4" src="../../assets/FP/FP (4).jpeg" alt="box chain photo" style="border-radius: 1.25rem!important; cursor: pointer;"  contain max-width="400" :class="$vuetify.breakpoint.lgAndUp ? 'ml-8' : 'mx-auto'">
            </v-img>
        </div>

        <v-dialog v-model="openDialog" width="1000px">
            <v-img v-if="imageToShow != ''" contain :src="imageToShow"></v-img>
        </v-dialog>

        <v-row no-gutters class="mb-3">
            <v-col cols="12" md="6">
                <div class="hero2">
                    <p class="text-h4 white--text pt-8">
                        How to
                    </p>
                    <h2 class="text-h2 mb-5 white--text">
                        Chainsaf
                    </h2>
                    <p class="text-h5" style="color:white;">
                        See how using chainsaf can save you time and effort.
                    </p>
                    <v-btn color="#540100" class="white--text" link to="/HowTo/Use">
                        Learn how
                    </v-btn>
                </div>
            </v-col>
            <v-col cols="12" md="6">
                <div class="hero3">
                    <p class="text-h4 pt-8 white--text" >
                        How to
                    </p>
                    <h2 class="text-h2 mb-5 white--text">
                        Install Chainsaf
                    </h2>
                    <p class="text-h5" style="color:white;">
                        Watch easy installation video.
                    </p>
                    <v-btn color="#540100" class="white--text" link to="/HowTo/Install">
                        Learn how
                    </v-btn>
                </div>
            </v-col>
        </v-row>
         
        <h2 class="text-h4 text-center">
            Latest Videos
        </h2>

        <v-container>
            <v-row>
                <v-col v-for="video in videos" :key="video.Id" cols="12" md="4">
                        <video-card :video="video"></video-card>
                </v-col>
            </v-row>
            <v-row v-if="loadingVideos">
                <v-col v-for="index in 3" :key="`temp-video-${index}`" cols="12" md="4">
                    <v-skeleton-loader
                    :loading="loadingVideos"
                    type="image"
                    height="250"/>
                </v-col>
            </v-row>
            <div class="mx-auto mt-4">
                <v-btn color="#540100" class="white--text" @click="$router.push({name: 'Videos'})">
                    Look at all videos
                </v-btn>
            </div>
        </v-container>

        
        
        <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. -->
    </div>
</template>

<script>
import firebase from 'firebase'
import VideoCard from './VideoCard.vue'
    export default {
        components:{
            VideoCard
        },
        data() {
            return {
                videos: [],
                loadingVideos: false,
                isActive: false,
                firstIsVisible: false,
                openDialog: false,
                imageToShow: ''
            }
        },
        methods: {
            firstVisibilityChanged (isVisible, entry) {
                this.firstIsVisible = isVisible
                console.log(entry)
            },
            GetVideos(){
                this.loadingVideos = true;
                var next = firebase.firestore().collection("Videos")
                .orderBy("Video.CreateDate", "desc")
                .limit(3)
                
                next.get().then((response) => {
                    this.videos = [];
                    response.forEach((doc) => {
                        var tempVid = doc.data().Video;
                            this.$set(tempVid, 'Id', doc.id)
                            this.$set(this.videos, this.videos.length, tempVid);
                    });
                })
                .finally(() =>{
                    this.loadingVideos = false;
                })
            }
        },
        mounted() {
            this.GetVideos();
        },
    }
</script>

<style scoped>
.hero {
    height: 425px;
    width: 100%;
    background:url('../../assets/Chainsaf(2dark).jpg')no-repeat center center;
    -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.hero2{
    height: 400px;
    width: 100%;
    background:url('../../assets/IMG_1414.jpg')no-repeat 
    center center ; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.hero3{
    height: 400px;
    width: 100%;
    background:url('../../assets/Chainsaf(6dark).jpg')no-repeat 
    center center ; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}



h1 {
  position: relative;
  color: #ffffff;  
  font-size: 14rem;
  line-height: 0.9;
  text-align: center;
}

.slide-in {
  z-index: 10; /* to position it in front of the other content */
  overflow: hidden; /* to prevent scrollbar appearing */
}

.slide-in.from-left {
  left: 0;
}

.slide-in.from-right {
  right: 0;
}

.slide-in-content {
  padding: 5px 20px;
  transition: transform .5s ease; /* our nice transition */
}

.slide-in.from-left .slide-in-content {
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
}

.slide-in.from-right .slide-in-content {
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
}

.slide-in.show .slide-in-content {
  transform: translateX(0);
  -webkit-transform: translateX(0);
}
.cs-primary{
    background-color:#540100;
    color: white;
}
.cs-primary-text{
    color:#540100;
}
</style>